import { call, put, select, takeLatest } from "redux-saga/effects";
import {
  BasketRequest,
  OrderRequest,
  SetShippingOptionRequest,
  UpdateBasketRequest,
} from "./../../../api/types/requests/cartRequest";
import { PayloadAction } from "@reduxjs/toolkit";
import {
  UPDATE_CART_BASKET,
  ADD_ITEM_BASKET,
  DELETE_BASKET_ITEM,
  GET_SHOPPING_BASKET,
  CartType,
  SET_SHIPPING_OPTION,
  CREATE_ORDER,
  BUY_NOW_ITEM,
  BUY_NOW_CANCEL,
} from "./CartType";
import { ApiSimpleResponse, BaseResponse } from "api/types/responses";
import {
  addItemToShoppingBasketCall,
  createOrderCall,
  deleteShoppingItemCall,
  getShoppingBasketCall,
  putShoppingBasketCall,
  setShippingOptionCall,
  stripeCall,
} from "api/cartCalls/basketCall";
import {
  addItemErrorAction,
  addItemSuccessAction,
  createOrderErrorAction,
  createOrderSuccessAction,
  getShoppingBasketErrorAction,
  getShoppingBasketNoBasketAction,
  getShoppingBasketSuccessAction,
  updateShoppingBasketErrorAction,
  updateShoppingBasketSuccessAction,
} from "./cartSlice";
import { cookies } from "utils/cookies";
import { toast } from "react-toastify";
import { SelectedProduct } from "utils/generalTypes/Frontend";
import { RootState } from "redux/app/store";
import { t } from "i18next";
import { i18Enum } from "i18n/types/translationType";
import { toastErrorStyle, toastSuccessStyle } from "utils/const/const";
import { filterItems } from "utils/functions/filterItems";

// const getItemsFromCart = (state: CartStateType) => state.cart.data?.ShoppingBasket?.Shops;
// const isBuyNowSelector = (state: CartStateType) => state.cart.data?.isBuyNow;
const processingProducts = (state: RootState) =>
  state.cart.cart.data?.ShoppingBasket?.processingProducts;

function* buyNowSaga({
  payload,
}: PayloadAction<{ item: SelectedProduct; action: () => void }>) {
  const request: BasketRequest = {
    Id: undefined,
    Item: payload.item,
  };

  const res: ApiSimpleResponse<BaseResponse<CartType>> =
    yield addItemToShoppingBasketCall(request);

  if (
    !res.isSuccess ||
    !res.response?.Data ||
    !res.response.Data.ShoppingBasket?.Id
  ) {
    yield put(addItemErrorAction(res.error ?? ""));
    toast.error(res.error, toastErrorStyle);
    return;
  }
  yield cookies.set("buyNowBasketId", res.response.Data.ShoppingBasket.Id);
  yield put(addItemSuccessAction(res.response.Data));
  payload.action();
}

function* AddItemToBasketSaga({ payload }: PayloadAction<SelectedProduct>) {
  const basketId: string | undefined = yield cookies.get("shoppingBasketId");

  // const itemsFromCart: ShoppingBasketItem[] | undefined = yield select(getItemsFromCart)
  // let oldItemsToSetShippingOption = itemsFromCart ? itemsFromCart.map(x => { return { key: x.productId, value: null } }) : undefined
  // let newItems = { key: payload, value: null }
  // let newItemsToSetShippingOption = oldItemsToSetShippingOption ? [...oldItemsToSetShippingOption, newItems] : [newItems]
  // yield put(updateShoppingBasketAction({
  //     itemsToSetShippingOption: newItemsToSetShippingOption
  // }));
  const request: BasketRequest = {
    Id: basketId,
    Item: payload,
  };

  const res: ApiSimpleResponse<BaseResponse<CartType>> =
    yield addItemToShoppingBasketCall(request);

  if (
    !res.isSuccess ||
    !res.response?.Data ||
    !res.response.Data.ShoppingBasket?.Id
  ) {
    yield put(addItemErrorAction(res.error ?? ""));
    toast.error(t(i18Enum.Error_MissingSize), toastErrorStyle);
    return;
  }
  if (!basketId) {
    yield cookies.set("shoppingBasketId", res.response.Data.ShoppingBasket.Id);
  }
  // const getNewBasket: ApiSimpleResponse<Cart> = yield getShoppingBasketCall(
  //     basketId ?? res.response.id
  // )
  // console.log('seconda chiamata 2', { getNewBasket })
  // if (!getNewBasket.isSuccess || !getNewBasket.response) {
  //     yield put(addItemErrorAction(res.error ?? ''));
  //     return
  // }
  yield put(addItemSuccessAction(res.response.Data));

  const items = filterItems(res.response.Data.ShoppingBasket.Shops);
  const addToCartItem = items?.filter((item) => item.item_id === payload.Id);
  window.dataLayer.push({ ecommerce: null });
  window.dataLayer.push({
    event: "add_to_cart",
    ecommerce: {
      currency: "EUR",
      value: addToCartItem?.[0].price,
      items: addToCartItem,
    },
  });
  console.log(addToCartItem);
  toast.success(
    t(i18Enum.Product_ProductCard_AddItemSuccess),
    toastSuccessStyle
  );
}

function* UpdateBasketSaga({ payload }: PayloadAction<UpdateBasketRequest>) {
  const res: ApiSimpleResponse<BaseResponse<CartType>> =
    yield putShoppingBasketCall(payload);
  if (!res.isSuccess || !res.response?.Data) {
    yield put(
      updateShoppingBasketErrorAction(res.error ?? t(i18Enum.Error_Occurred))
    );
    return;
  }

  const processingProductsList: string[] | undefined = yield select(
    processingProducts
  );
  const unProcessedProducts = processingProductsList?.filter(
    (x) => x !== payload.Id
  );
  const response: CartType = {
    ...res.response.Data,
    ShoppingBasket: {
      ...res.response.Data.ShoppingBasket,
      processingProducts: unProcessedProducts,
    },
  };
  yield put(updateShoppingBasketSuccessAction(response));
}

function* DeleteItemSaga({ payload }: PayloadAction<string>) {
  const res: ApiSimpleResponse<BaseResponse<CartType>> =
    yield deleteShoppingItemCall(payload);
  if (!res.isSuccess || !res.response?.Data) {
    yield put(
      updateShoppingBasketErrorAction(res.error ?? t(i18Enum.Error_Occurred))
    );
    return;
  }
  yield put(updateShoppingBasketSuccessAction(res.response.Data));
}

function* GetShoppingBasketSaga() {
  const basketId: string | undefined = yield cookies.get("shoppingBasketId");
  if (!basketId) {
    yield put(getShoppingBasketNoBasketAction());
    return;
  }
  const res: ApiSimpleResponse<BaseResponse<CartType>> =
    yield getShoppingBasketCall(basketId);
  if (!res.isSuccess || !res.response?.Data) {
    yield put(
      getShoppingBasketErrorAction(res.error ?? t(i18Enum.Error_Occurred))
    );
    return;
  }
  yield put(getShoppingBasketSuccessAction(res.response.Data));
}

function* SetShippingOptionSaga({
  payload,
}: PayloadAction<{ req: SetShippingOptionRequest; action: () => void }>) {
  const res: ApiSimpleResponse<BaseResponse<CartType>> =
    yield setShippingOptionCall(payload.req);
  if (!res.isSuccess || !res.response?.Data) {
    yield put(
      getShoppingBasketErrorAction(res.error ?? t(i18Enum.Error_Occurred))
    );
    toast.error(res.error, toastErrorStyle);
    return;
  }
  yield put(getShoppingBasketSuccessAction(res.response.Data));
  payload.action();
}

function* CreateOrderSaga({
  payload,
}: PayloadAction<{ req: OrderRequest; action: (par: string) => void }>) {
  const isBuyNow: boolean | undefined = yield select(
    (state: RootState) => state.cart.cart.data?.isBuyNow
  );

  const res: ApiSimpleResponse<BaseResponse<string>> = yield createOrderCall(
    payload.req
  );
  if (!res.isSuccess || !res.response?.Data) {
    toast.error(res.response?.ErrorMessage, toastErrorStyle);
    yield put(createOrderErrorAction(res.response?.ErrorMessage ?? ""));
    return;
  }

  const stripe: ApiSimpleResponse<BaseResponse<string>> = yield stripeCall(
    res.response.Data
  );
  if (!stripe.isSuccess || !stripe.response?.Data) {
    toast.error(stripe.response?.ErrorMessage, toastErrorStyle);
    yield put(createOrderErrorAction(stripe.response?.ErrorMessage ?? ""));
    return;
  }

  if (isBuyNow) {
    yield cookies.remove("buyNowBasketId");
    yield put(createOrderSuccessAction());
    payload.action(encodeURIComponent(stripe.response.Data));
    return;
  } else {
    yield cookies.remove("shoppingBasketId");
    yield put(createOrderSuccessAction());
    payload.action(encodeURIComponent(stripe.response.Data));
  }
}

function* BuyNowDeleteSaga() {
  const buyNowId: string | undefined = yield cookies.get("buyNowBasketId");
  if (!!buyNowId) {
    yield cookies.remove("buyNowBasketId");
  }
  yield call(GetShoppingBasketSaga);
}

export function* watchCart() {
  yield takeLatest(UPDATE_CART_BASKET, UpdateBasketSaga);
  yield takeLatest(ADD_ITEM_BASKET, AddItemToBasketSaga);
  yield takeLatest(BUY_NOW_ITEM, buyNowSaga);
  yield takeLatest(DELETE_BASKET_ITEM, DeleteItemSaga);
  yield takeLatest(GET_SHOPPING_BASKET, GetShoppingBasketSaga);
  yield takeLatest(SET_SHIPPING_OPTION, SetShippingOptionSaga);
  yield takeLatest(CREATE_ORDER, CreateOrderSaga);
  yield takeLatest(BUY_NOW_CANCEL, BuyNowDeleteSaga);
}
