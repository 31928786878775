/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useMemo } from "react";
import styled from "styled-components";

import { useMediaQuery } from "@uidotdev/usehooks";
import { ModalContext } from "../../../context/ModalContext";
import { CustomThemeContext } from "context/ThemeContext";
import { ModalTypeEnum } from "../../../utils/generalTypes/enum";
import { useScrollBlock } from "../../../utils/hooks/useScrollBlock";
import Flex from "../../primitives/Flex";
import CrossSvg from "components/atoms/CrossSvg";
import { DotsLoader } from "components/molecules/DotsLoader";

const Modal = () => {
  const { show, closeModal, child, isLoading, modalType } = useContext(ModalContext);
  const { customTheme } = useContext(CustomThemeContext);
  const colors = customTheme.colors;
  const isMobile = useMediaQuery("screen and (max-width : 950px)");
  const isPotrait = useMediaQuery("screen and (orientation: portrait)");
  const [blockScroll, allowScroll] = useScrollBlock();

  const toggleScroll = (enable: boolean) => {
    console.log(enable);
    if (enable) {
      blockScroll();
    } else {
      allowScroll();
    }
  };

  useEffect(() => {
    toggleScroll(show);
    return () => toggleScroll(show);
  }, [show]);

  useEffect(() => {
    return () => {
      allowScroll();
    };
  }, []);

  const handleCloseClick = () => {
    closeModal();
  };

  const popupModalCss = {
    width: `${isMobile ? "95%" : "1200px"}`,
    height: `${isMobile ? "90%" : "85%"}`,
  };

  const messageModalCss = {
    width: `${isMobile ? "80%" : "30%"}`,
    height: `${isMobile ? "40%" : "40%"}`,
    minHeight: `${isMobile ? "40%" : "40%"}`,
    color: colors.thirdBase,
    backgroundColor: colors.secondaryBase,
    border: `1px solid ${colors.thirdBase}`,
  };

  const uploadImageModalCss = {
    width: `${isMobile ? "100%" : "30%"}`,
    height: `${isMobile ? "100%" : "60%"}`,
    minHeight: `${isMobile ? "50%" : "60%"}`,
    color: colors.thirdBase,
    backgroundColor: colors.secondaryBase,
  };

  const selectCSS = useMemo(() => {
    switch (modalType) {
      case ModalTypeEnum.popup:
        return popupModalCss;
      case ModalTypeEnum.message:
        return messageModalCss;
      case ModalTypeEnum.uploadImage:
        return uploadImageModalCss;
    }
  }, [modalType, isMobile, isPotrait]);

  const modalContent = show ? (
    <StyledModalOverlay>
      <StyledModal style={selectCSS}>
        {modalType === ModalTypeEnum.popup ? (
          child
        ) : (
          <>
            <StyledModalHeader>{modalType === ModalTypeEnum.uploadImage && <CrossSvg handleClick={() => handleCloseClick()} />}</StyledModalHeader>

            <StyledModalBody>
              {isLoading ? (
                <Flex justifyContent='center' alignItems='center'>
                  <DotsLoader colors={colors.thirdBase} />
                </Flex>
              ) : (
                <Flex width={"100%"} height={"100%"} justifyContent={"center"}>
                  {child}
                </Flex>
              )}
            </StyledModalBody>
          </>
        )}
      </StyledModal>
    </StyledModalOverlay>
  ) : null;

  return <>{modalContent}</>;
};

export const StyledModalBody = styled.div`
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  overflow-y: hidden;
  color: ${(props) => props.theme.colors.secondaryBase};

  &::-webkit-scrollbar {
    -webkit-appearance: none;
  }
`;
export const StyledModalHeader = styled.div`
  display: flex;
  justify-content: flex-end;
  font-size: 25px;
`;

export const StyledModal = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #17181c;
  border: 1px solid ${(props) => props.theme.colors.thirdBase};
  z-index: 999;
  font-family: "BauPro";
  font-size: 16px;
  /* border-radius: 10px; */
  padding: 1.25em;
  position: fixed;
`;
export const StyledModalOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100dvh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999999;
`;

export default Modal;
