import { useEffect, useState } from "react";
import styled from "styled-components";
import { BorderProps, HeightProps, PositionProps, WidthProps, MaxWidthProps, width, space, color, layout, border, height, SpaceProps, maxWidth, compose, variant, LayoutProps } from "styled-system";

export interface VideoProps extends PositionProps, WidthProps, MaxWidthProps, HeightProps, BorderProps, SpaceProps {
  transform?: string;
  opacity?: number;
}

interface VideoPlayerProps extends VideoProps {
  src: string;
  isVideoMuted: boolean;
  handleClick: () => void;
  aspectRatio?: string;
}

const VideoPlayer: React.FC<VideoPlayerProps & VideoProps> = ({ src, isVideoMuted, handleClick, aspectRatio }) => {
  const [videoSrc, setVideoSrc] = useState(src);
  const [variant, setVariant] = useState<"loaded" | "loading">("loading");

  useEffect(() => {
    const video = document.createElement("video");

    const handleLoadedMetadata = () => {
      setVideoSrc(src);
      setVariant("loaded");
    };

    video.src = src;
    video.addEventListener("loadedmetadata", handleLoadedMetadata);

    return () => {
      video.removeEventListener("loadedmetadata", handleLoadedMetadata);
    };
  }, [src]);

  return (
    <StyledVideoPlayer
      variant={variant}
      src={videoSrc}
      poster={videoSrc}
      aspectRatio={aspectRatio}
      muted={isVideoMuted}
      autoPlay
      loop
      webkit-playsinline
      playsInline
      controlsList='nofullscreen'
      onClick={handleClick}
    />
  );
};

interface StyledVideoProps extends SpaceProps, LayoutProps, BorderProps {
  variant?: "loaded" | "loading";
  objectFit?: "cover" | "contain" | "fill" | "none" | "scale-down";
  objectPosition?: "bottom" | "center" | "left" | "right" | "top" | "top-left" | "top-right" | "bottom-left" | "bottom-right";
}

const StyledVideoPlayer = styled.video<VideoProps & StyledVideoProps & { aspectRatio?: string }>`
  transform: ${(props) => props.transform || "none"};
  height: 100%;
  object-fit: ${(props) => props.objectFit || "cover"};
  object-position: ${(props) => props.objectPosition || "center"};
  aspect-ratio: ${(props) => props.aspectRatio || "auto"};
  border-radius: 16px;
  cursor: pointer;

  ${() =>
    compose(
      variant({
        variants: {
          loaded: {
            filter: "blur(0px)",
            transition: " filter 0.5s linear",
          },
          loading: {
            filter: "blur(10px)",
            clipPath: "inset(0)",
          },
        },
      })
    )}

  ${space}
  ${color}
  ${layout}
  ${border}
  ${width}
  ${height}
  ${maxWidth}
`;

export default VideoPlayer;
