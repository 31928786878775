import { ProductsListRequest } from "api/types/requests/productRequest";
import request from "graphql-request";

const GRAPHQL_API ="http://localhost:4000/graphql";

const allProucts =/* GraphQL */`
  query getProducts {
    getProducts {
      Data {
        Id
        ShopId
        Code
        Name
        Variants {
          Sku
          IsSpicy
          ProductId
        }
        Order
        Shop {
          Id
          Name
        }
      }
      Pager {
        PageNum
        PageSize
        TotalItems
        TotalPages
        Links {
          Label
          Active
          Page
        }
      }
    }
  }
  `;

export const getProducts = async (params:ProductsListRequest) =>{
  console.log(params);
  return  request(GRAPHQL_API, allProucts, undefined);
}
 
