import { deleteAriesCall, getAriesCall, patchAriesCall, postAriesCall, putAriesCall } from "api/baseAriesCall";
import { getCall, postCall, putCall } from "api/baseCall";
import { BasketRequest, OrderRequest, SetShippingOptionRequest, StripeRequest, UpdateBasketRequest, UpdateShippingRequest } from "api/types/requests/cartRequest";
import { BaseResponse, CoreResponse } from "api/types/responses";
import { ShoppingBasket } from "redux/features/cart/CartType";
import { OrderDTO } from "redux/features/user/UserTypes";
import { PAGE } from "utils/const/pagePath";


const ORDER_BASKET_CONTROLLER = 'Order/Basket'
const ORDER_CONTROLLER = 'Order'
const STRIPE_CONTROLLER = "/Payments/createpayment"


export const getShoppingBasketCall = async (shoppingBasketId?: string) =>
    await getAriesCall<BaseResponse<ShoppingBasket>>(`${ORDER_BASKET_CONTROLLER}/${shoppingBasketId}`)



export const addItemToShoppingBasketCall = async (req: BasketRequest) =>
    await postAriesCall<BasketRequest, BaseResponse<ShoppingBasket>>(ORDER_BASKET_CONTROLLER, req)


export const putShoppingBasketCall = async (req: UpdateBasketRequest) =>
    await putAriesCall<UpdateBasketRequest, BaseResponse<ShoppingBasket>>(ORDER_BASKET_CONTROLLER, req)


export const deleteShoppingItemCall = async (id: string) =>
    await deleteAriesCall<BaseResponse<ShoppingBasket>>(`${ORDER_BASKET_CONTROLLER}/${id}`)


export const setShippingOptionCall = async (req:SetShippingOptionRequest) =>{
    req.ShopsShippings.forEach((el)=>el.price=undefined)
    const resp = await patchAriesCall<{ShopsShippings :Partial<UpdateShippingRequest>[]},BaseResponse<ShoppingBasket>>(`${ORDER_BASKET_CONTROLLER}/${req.ShoppingBasketId}`, {ShopsShippings:req.ShopsShippings})
    return resp
}

export const createOrderCall = async (req:OrderRequest)=>{
 let res =   await postAriesCall<OrderRequest, BaseResponse<string> >(`${ORDER_CONTROLLER}`,req)
 return res
}

export const  stripeCall = async (orderId:string)=>{
    let res = await postAriesCall<StripeRequest, BaseResponse<string> >(`${STRIPE_CONTROLLER}`, {
        OrderId: orderId,
        SuccessUrl: window.location.origin + PAGE.paymentSuccess.menuPath,
        CancelUrl: window.location.origin + PAGE.paymentError.menuPath,
     })

     return res
}