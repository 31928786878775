import styled, { keyframes } from "styled-components";

import {
  ColorProps,
  SpaceProps,
  BorderProps,
  LayoutProps,
  space,
  color,
  layout,
  border,
  width,
  height,
  compose,
  variant,


} from "styled-system";
// background-image: -webkit-linear-gradient(left, #ececec 0px, #f4f4f4 40px, #ececec 80px);
// background-image: -o-linear-gradient(left, #ececec 0px, #f4f4f4 40px, #ececec 80px);
// background-image: linear-gradient(90deg, #ececec 0px, #f4f4f4 40px, #ececec 80px);
// background-size: 250px;
// -webkit-animation: shine-loading-image 2s infinite ease-out;
//         animation: shine-loading-image 2s infinite ease-out;


const skeletonKeyframe = keyframes`
    0%{background-position:50% 0%}
    50%{background-position:50% 100%}
    100%{background-position:50% 0%}
`


interface ImageProps extends ColorProps, SpaceProps, LayoutProps, BorderProps {
  variant?: 'loaded' | 'loading'
  objectFit?: "cover" | "contain" | "fill" | "none" | "scale-down";
  objectPosition?:
  | "bottom"
  | "center"
  | "left"
  | "right"
  | "top"
  | "top-left"
  | "top-right"
  | "bottom-left"
  | "bottom-right"
}


const Image = styled.img<ImageProps & { $aspectRatio?: string }  >`
  ${space}
  ${color}
  ${layout}
  ${border}
  ${width}
  ${height} 
  object-fit: ${(props) => props.objectFit || "cover"};
  animation:  ${skeletonKeyframe} 2s ease-in-out alternate infinite;
  object-position: ${(props) => props.objectPosition || "center"};
  aspect-ratio: ${(props) => props.$aspectRatio ?? undefined};
  ${(props) =>
    compose(
      variant({
        variants: {
          loaded: {
            filter: 'blur(0px)',
            transition:' filter 0.5s linear',
          },
          loading: {
            filter: 'blur(10px)',
            clipPath: 'inset(0)',
        

        }
      }
      }))}
`;

export default Image;
