import "./App.css";
import { RouterProvider } from "react-router-dom";
import { router } from "./routing/routes";
import { ToastContainer } from "react-toastify";
import { AppGeneralContext } from "./context/AppGeneralContext";
import { useContext, useEffect, useState } from "react";
import { ThemeProvider } from "styled-components";
import { CustomThemeContext } from "./context/ThemeContext";
import GlobalStyle from "./theme/globalStyle";
import GlobalFonts from "./assets/fonts/fonts";
import "react-toastify/dist/ReactToastify.css";
import { getAuth } from "api/authHelpers";
import { useAppDispatch } from "redux/app/hooks";
import { askAuthSuccessAction } from "redux/features/auth/authSlice";
import { getUserAction } from "redux/features/user/userSlice";
import { useTranslation } from "react-i18next";
import Modal from "components/organisms/Modal/Modal";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

const MINUTE = 1000 * 60;
export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 50 * MINUTE, // 50 min
      gcTime: 60 * MINUTE, // 60 min
    },
  },
});

function App() {
  const {
    // filterList: categoriesTag,
    isMobile,
    // isLoading,
    setIsLoading,
  } = useContext(AppGeneralContext);
  const { customTheme } = useContext(CustomThemeContext);
  const [initializing, setInitializing] = useState<boolean>(true);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const InitializeApp = async () => {
    const jwt = getAuth();
    if (jwt) {
      dispatch(askAuthSuccessAction(jwt));
      dispatch(getUserAction());
    }
    setInitializing(false);
    //TODO: subdivide requests here

    // const homeDataResp = await getHomePageData();
    // dispatch(cartActions.getShoppingBasketAction());
    // const catResp = await getFilterTagsCall();
    // if (
    //   !catResp.isSuccess ||
    //   !homeDataResp.isSuccess ||
    //   !homeDataResp.response?.Data
    // ) {
    //   setInitializing(false);
    //   addToast(toastType.error, t(i18Enum.Error_Occurred));
    //   return;
    // } else {
    //   setParsedSpicyProduct(homeDataResp.response?.Data?.Products);
    //   setHomepageSliderContent(homeDataResp.response.Data.Slider);
    //   setHomepagePopup(homeDataResp.response.Data.Popup);
    //   // setOutfits(homeDataResp.response?.Data?.Outfits)
    //   dispatch(
    //     OutfitsActions.setOutfitsHomePageAction(
    //       homeDataResp.response.Data.Outfits[0]
    //     )
    //   );
    //   setCategories(catResp.response?.Data);
    //   setInitializing(false);
    // }
  };
  useEffect(() => {
    setIsLoading(initializing);
  }, [initializing]);

  useEffect(() => {
    InitializeApp();
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} />
      <ThemeProvider theme={customTheme}>
        <GlobalFonts />
        <GlobalStyle theme={customTheme} />

        <RouterProvider
          router={router}
          fallbackElement={<p>Initial Load...</p>}
        />

        <ToastContainer
          position={isMobile ? "bottom-right" : "top-right"}
          theme="dark"
          icon={({ type }) => {
            if (type === "success") return "✌🏻";
            if (type === "error") return "🚩";
            if (type === "warning") return "⚠️";
            else return "🫶🏼";
          }}
        />

        <Modal />
      </ThemeProvider>
    </QueryClientProvider>
  );
}

export default App;
