import { Fragment, PropsWithChildren, useContext } from "react";
import { CustomThemeContext } from "context/ThemeContext";
import { CartCardBody } from "components/atoms/CartCardBody";
import Flex from "components/primitives/Flex";
import Txt from "components/primitives/Txt";
import SelectColor from "./SelectColor";
import { priceNum } from "utils/functions/stringPrice";
import { H6 } from "theme/theme.styled";
import { CartInfoWrapper } from "components/primitives/CartInfoWrapper";
import { StatusOrder } from "./Order";
import { OrderDTO, ShippingDTO } from "redux/features/user/UserTypes";
import { MultiColorKeyGradient, VariantColor, colorsKeyEnum } from "utils";
import { i18Enum } from "i18n/types/translationType";
import { useTranslation } from "react-i18next";
import Tooltip from "components/primitives/Tooltip";
interface IOrderSentCard {
  items?: ShippingDTO[];
  shop?: OrderDTO;
  variants: "order" | "cart";
}

export const OrderSentCard = ({ items, shop, variants }: PropsWithChildren<IOrderSentCard>) => {
  const { customTheme } = useContext(CustomThemeContext);
  const colors = customTheme.colors;
  const { t } = useTranslation();

  return (
    <>
      {items &&
        items?.map((product) => (
          <Fragment key={product.Id}>
            <CartCardBody
              opacity={product.Status === StatusOrder.Deleted || product.Status === StatusOrder.Canceled ? 0.3 : 1}
              variant='orders'
              background={product?.ProductVariant?.Images?.[0].Url ?? ""}
              quantity={product.ItemCount}
              height={"200px"}
            >
              <Flex flexDirection={"column"} justifyContent={"flex-start"} paddingBottom={[3]} width={"80%"}>
                <Txt textAlign={"start"} as={"h6"} variant='medium' color={colors.thirdText} fontSize={H6} margin={"0 0 5% 0"}>
                  {product.ProductVariant?.ProductName}
                </Txt>

                <Flex $gap={0.5} margin={"0 0 10% 0"}>
                  <Txt variant={product?.ProductVariant?.DiscountedPrice && product?.ProductVariant?.DiscountedPrice !== 0 ? "crossed" : "medium"} color={colors.thirdText} fontSize={[2]}>
                    {`${priceNum(product?.ProductVariant?.Price ?? 0)}€`}
                  </Txt>

                  {product?.ProductVariant?.DiscountedPrice && product?.ProductVariant?.DiscountedPrice !== 0 && (
                    <Txt variant='medium' color={colors.primaryText} fontSize={[2]}>
                      {`${priceNum(product.ProductVariant?.DiscountedPrice)}€`}
                    </Txt>
                  )}
                </Flex>
                <Flex $gap={1} alignItems={"center"} minHeight={"50px"}>
                  {product.SelectedTags &&
                    product.SelectedTags.map((tag) => {
                      if (VariantColor(tag.Label)) {
                        return (
                          <Tooltip content={tag.Label ?? ""} key={tag.Id}>
                            <SelectColor
                              isBlack={tag.Label === "Black"}
                              backgroundImage={tag.Label === colorsKeyEnum.Multicolour ? MultiColorKeyGradient : "unset"}
                              background={VariantColor(tag.Label ?? "")}
                              selected={true}
                            />
                          </Tooltip>
                        );
                      } else {
                        return (
                          <Txt key={tag.Id} variant='light' color={colors.thirdText} fontSize={[2]}>
                            {tag.Label}
                          </Txt>
                        );
                      }
                    })}
                </Flex>
              </Flex>
            </CartCardBody>
          </Fragment>
        ))}
      <Flex justifyContent={"flex-end"} padding={"5%"} borderBottom={`1px solid ${colors.thirdBase}`} $gap={2}>
        {variants === "cart" && <CartInfoWrapper text={t(i18Enum.Cart_Checkout_Title)} price={priceNum(shop?.Total)} colorText={colors.thirdText} colorPrice={colors.thirdText} />}
        <CartInfoWrapper
          text={t(i18Enum.Orders_Order_Shipping)}
          price={shop?.ItemsTotal && shop?.Total ? priceNum(shop?.Total - shop?.ItemsTotal) : 0}
          colorText={colors.thirdText}
          colorPrice={colors.thirdText}
        />
        <CartInfoWrapper text={t(i18Enum.Common_Total)} price={shop?.Total} colorText={colors.primaryText} colorPrice={colors.primaryText} />
        {/* //TODO  gestire lo status Order  */}
        {variants === "order" && (
          <CartInfoWrapper
            text={t(i18Enum.Orders_Order_Status)}
            price={StatusOrder[shop?.Status ?? 0]}
            colorText={colors.thirdText}
            colorPrice={
              shop?.Status === StatusOrder.Deleted
                ? colors.dangerBase
                : shop?.Status === StatusOrder.Canceled
                ? colors.warningBase
                : shop?.Status === StatusOrder.Accepted
                ? colors.primaryBase
                : colors.thirdText
            }
          />
        )}
      </Flex>
    </>
  );
};
