import { SpaceProps, WidthProps } from "styled-system";
import Flex from "../primitives/Flex";
import Box from "components/primitives/Box";
import Txt from "components/primitives/Txt";
import Grid from "components/primitives/Grid";
import Checkbox from "components/primitives/Checkbox";
import { MobileNavigationBottom } from "components/atoms/MobileNavigationBottom";
import { useContext, useEffect } from "react";
import { AppGeneralContext } from "context/AppGeneralContext";
import Button from "components/primitives/Button";
import { FormField } from "./StepThirdForm";
import Form from "components/primitives/Form";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useAppSelector } from "redux/app/hooks";
import { useTranslation } from "react-i18next";
import { i18Enum } from "i18n/types/translationType";
export interface FormStepFourth {
  social?: number[];
}

const initialFourthStep: FormStepFourth = {
  social: undefined,
};
interface StepThirdFormProps extends WidthProps, SpaceProps {
  onSubmit: (value: FormStepFourth) => void;
}

type FormStepFourthSchemaObject = {
  [key in keyof FormStepFourth]: Yup.Schema<any>;
};

const formStepLoginFourthSchema = Yup.object().shape<FormStepFourthSchemaObject>({
  social: Yup.array().of(Yup.number()).nullable(),
});

function StepFourthForm({ onSubmit, ...props }: StepThirdFormProps) {
  const { isMobile } = useContext(AppGeneralContext);
  const { data: userPreference } = useAppSelector((state) => state.user.userPreference);
  const { t } = useTranslation();

  useEffect(() => {
    if (userPreference?.FavoriteSocials) {
      formik.setFieldValue("social", userPreference?.FavoriteSocials);
      formik.setFieldTouched("social", true, true);
      formik.setFieldError("social", undefined);
    }
  }, [userPreference, userPreference?.FavoriteSocials]);

  const formik = useFormik<FormStepFourth>({
    initialValues: initialFourthStep,
    validationSchema: formStepLoginFourthSchema,
    enableReinitialize: true,
    onSubmit: (values, { setSubmitting }) => {
      onSubmit(values);

      setSubmitting(false);
    },
  });

  const formFavoriteSocial: FormField[] = [
    {
      id: 0,
      label: t(i18Enum.DV_Socials_1),
    },
    {
      id: 1,
      label: t(i18Enum.DV_Socials_2),
    },
    {
      id: 2,
      label: t(i18Enum.DV_Socials_3),
    },
    {
      id: 3,
      label: t(i18Enum.DV_Socials_4),
    },
    {
      id: 4,
      label: t(i18Enum.DV_Socials_5),
    },
    {
      id: 5,
      label: t(i18Enum.DV_Socials_6),
    },
    {
      id: 6,
      label: t(i18Enum.DV_Socials_7),
    },
    {
      id: 7,
      label: t(i18Enum.DV_Socials_8),
    },
  ];

  function addItem(value: number) {
    const type = "social";
    const oldArr = formik.values.social !== undefined ? formik.values.social : [];
    const newArr = oldArr ? [...oldArr, value] : [value];
    formik.setFieldValue(type, newArr);
  }

  function deleteItem(value: number) {
    const type = "social";
    const oldArr = formik.values.social !== undefined ? formik.values.social : [];
    const newArr = oldArr?.filter((x) => x !== value);
    formik.setFieldValue(type, newArr);
  }

  const Label = ({ str }: { str: string }): JSX.Element => {
    return (
      <>
        <Txt variant={"light"} fontSize={[3]}>
          {str}
        </Txt>
      </>
    );
  };

  return (
    <>
      <Form flex={1} width={[1]} padding={"0 5%"} flexDirection={"column"} alignItems={"center"} justifyContent={"center"} onSubmit={formik.handleSubmit} {...props}>
        <Grid as='fieldset' gridTemplateColumns={["repeat(2, 1fr)"]} width={[1, 1, "500px"]} maxWidth={"500px"} padding={0} margin={0} border={"none"} $gap={1}>
          <Txt as='legend' variant={"light"} fontSize={[3]} textAlign={"end"} marginBottom={[2]}>
            {t(i18Enum.OnboardingQuiz_Socials_Header)}
          </Txt>
          {formFavoriteSocial.map((field, index) => (
            <Checkbox
              key={field.id}
              id={field.id.toString() + "social"}
              name={field.label}
              spaceProp={{ width: [1], marginTop: index === 0 ? "18px" : "18px" }}
              checked={formik.values.social ? formik.values.social.includes(field.id) : false}
              onChange={(e) => {
                if (!e.target.checked) {
                  deleteItem(field.id);
                  return;
                }
                addItem(field.id);
              }}
              children={<Label str={field.label} />}
            />
          ))}
        </Grid>
        {!isMobile && (
          <Flex width={[1]} paddingTop={"80px"} justifyContent={"space-between"} alignItems={"center"} maxWidth={"500px"}>
            <Txt variant='light' fontSize={[2]}>
              {t(i18Enum.OnboardingQuiz_StepSocials_Title)}
            </Txt>
            <Button type='submit' variant='primary' padding={"10px 20px"} disabled={!formik.isValid}>
              {t(i18Enum.Common_Next)}
            </Button>
          </Flex>
        )}
        <Box marginBottom={5} />
      </Form>

      {isMobile && (
        <MobileNavigationBottom
          text={t(i18Enum.Common_Next)}
          description={t(i18Enum.OnboardingQuiz_StepSocials_Title)}
          disabled={!formik.isValid}
          buttonAction={isMobile && formik.handleSubmit}
        />
      )}
    </>
  );
}

export default StepFourthForm;
