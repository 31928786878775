import { IState } from "../SharedStateType";
import { ProductType } from "../product/ProductsTypes";
import { PaginatedResponse } from "api/types/responses";

export type BrandType = {
    Id?: string;
    Description: string;
    Name: string;
    Picture?: string;
    About: string;
    CoverPicture: string;
    Products: ProductType[];
    Code: string;
}

// export type BrandExtendedType = {
//     shopcard?: BrandType;
//     selectedTab?: string;
//     tabNamespace?: string;
//     isFollowed?: boolean;
//     followersItems?: FollowersItem[];
//     // followersPage?: NewIPage<FollowersItem>;
//     profileShopId?: string;
//     isMyShop?: boolean;
//     keywordFilter?: string;
//     navigateToFollowers?: boolean;
//     shippingOptions?: ShopShippingOption[];
// }

interface FollowersItem {
    entityId?: string;
    picture?: string;
    name?: string;
    location?: string;
    coverPicture?: string;
    followersCount?: number;
    addedDate?: Date;
    following?: boolean;
}

export type BrandStateType = {
  brand: IState<BrandType>;
  brandsList: IState<PaginatedResponse<BrandType>>;
};


export const BRAND = 'brand';
export type BRAND = typeof BRAND


export const GET_BRAND_BY_ID = `${BRAND}/getBrandByIdAction`;
export type GET_BRAND_BY_ID = typeof GET_BRAND_BY_ID;
export const GET_BRANDS_LIST = `${BRAND}/getBrandsListAction`;
export type GET_BRANDS_LIST = typeof GET_BRANDS_LIST;

