// import BackgroundVideo from "../components/molecules/VideoBackgroud";

import { useContext, useEffect } from "react";
import { CustomThemeContext } from "../context/ThemeContext";

import { AppGeneralContext } from "../context/AppGeneralContext";
import { useNavigate } from "react-router";
import { PAGE } from "utils/const/pagePath";
import { useAppDispatch, useAppSelector } from "redux/app/hooks";
import { OutfitsActions } from "redux/features/outfits/outfitsSlice";
import { useTranslation } from "react-i18next";
import { ModalContext } from "context/ModalContext";
import { ModalTypeEnum } from "utils";
import { cookies } from "utils/cookies";
import { PopupSlider } from "components/organisms/PopupSlider";
import { saveCurrentTimestamp } from "utils/functions/saveCurrentTimestamp";
import Wip from "components/organisms/Wip";
// import SectionSlaiderField from "../components/organisms/SectionHomeSlider";
// import homeSectionSlider from "../api/mock/homeSectionSlider.json";
// import BackgroundVideo from "components/molecules/VideoBackgroud";
// import mock from "../api/mock/popup.json";

export const HomePage = () => {
  const { customTheme } = useContext(CustomThemeContext);
  const { openModalWithChildren } = useContext(ModalContext);
  const {
    isMobile,
    parsedSpicyProduct,
    homepageSliderContent,
    homepagePopup,
    isLoading,
  } = useContext(AppGeneralContext);
  const outfit = useAppSelector((state) => state.outfits.homePageOutfit.data);
  const color = customTheme.colors;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  // const section = useRef<HTMLDivElement | null>(null);
  // const sectionsAnimation: Variants = {
  //   offscreen: {
  //     y: -300,
  //     opacity: 0,
  //   },
  //   onscreen: {
  //     y: 0,
  //     opacity: 1,
  //     transition: {
  //       type: "spring",
  //       duration: 0.8,
  //     },
  //   },
  // };

  useEffect(() => {
    // if (isLoading) {
    //   return;
    // }
    //  openModalWithChildren(<PopupSlider data={mock} />, ModalTypeEnum.popup, () => saveCurrentTimestamp());

    if (isLoading || !homepagePopup || homepagePopup.length === 0) {
      return;
    }
    const cookieTimestamp = cookies.get("mooore-k-popup");
    const savedTimestamp = new Date(cookieTimestamp);
    const newTimestamp = new Date(homepagePopup[0].DateFrom);
    if (
      !cookieTimestamp ||
      (cookieTimestamp && savedTimestamp < newTimestamp)
    ) {
      openModalWithChildren(
        <PopupSlider data={homepagePopup} />,
        ModalTypeEnum.popup,
        () => saveCurrentTimestamp()
      );
    } else return;
  }, [isLoading]);

  function showOutfit() {
    if (!outfit) {
      return;
    }
    dispatch(OutfitsActions.getOutfitsListAction());
    dispatch(OutfitsActions.setSelectedOutfitAction(outfit));
    navigate(PAGE.outfit.menuPath);
  }

  function goToShopping() {
    navigate(PAGE.shopList.menuPath);
  }

  function discover() {
    window.open(
      "https://magazine.mooore.com/la-prima-cover-per-iphone-che-si-pianta-e-germoglia/"
    );
  }

  function about() {
    navigate(PAGE.aboutUs.menuPath);
  }

  return (
    <div className="w-full">
      <Wip />
    </div>
  );
};
