import Txt from "../primitives/Txt";
import Button from "../primitives/Button";
import { SpaceProps, WidthProps } from "styled-system";
import { useFormik } from "formik";
import * as Yup from "yup";
import Form from "components/primitives/Form";
import InputFieldWardrobe from "components/molecules/InputFieldWardrobe";
import { i18Enum } from "i18n/types/translationType";
import { useTranslation } from "react-i18next";

type LoginSchemaObject = {
  [key in keyof FormLogin]: Yup.Schema<any>;
};
export interface FormLogin {
  email: string;
  password: string;
}

const loginInitialValue: FormLogin = {
  email: "",
  password: "",
};
interface LoginProps extends WidthProps, SpaceProps {
  onSubmit: (value: FormLogin) => void;
  setEmail: (value: string) => void;
}

function LoginForm({ onSubmit, setEmail, ...props }: LoginProps) {
  const { t } = useTranslation();

  const loginSchema = Yup.object().shape<LoginSchemaObject>({
    email: Yup.string()
      .matches(/^[\w\-.]+@([\w-]+\.)+[a-zA-Z]{2,10}$/i, t(i18Enum.Validation_Client_Field_Email))
      .required(t(i18Enum.Validation_Client_Field_Required)),
    password: Yup.string().required(t(i18Enum.Validation_Client_Field_Required)),
  });

  const formik = useFormik<FormLogin>({
    initialValues: loginInitialValue,
    validationSchema: loginSchema,
    onSubmit: async (values, { setSubmitting }) => {
      //loading
      setSubmitting(true);
      if (values.email && values.password) {
        await onSubmit(values);
      }
      formik.resetForm();
    },
  });

  return (
    <Form as='form' flexDirection={"column"} autoComplete='off' id='formLogin' alignItems={"end"} zIndex={10} {...props}>
      <Txt as='legend' width={[1]} fontSize={[4]} variant='light' textAlign={"left"}>
        {t(i18Enum.Login_LoginTitle)}
      </Txt>
      <InputFieldWardrobe
        {...formik.getFieldProps("email")}
        name={"email"}
        id={"email"}
        type={"email"}
        placeholder={t(i18Enum.User_UserProfile_Label_Email)}
        label={"email"}
        spaceProp={{ width: [1], marginTop: [4], padding: ["10px 0"] }}
        onChange={(e) => {
          formik.handleChange(e);
          setEmail(e.target.value);
        }}
        onBlur={() => formik.setFieldTouched("email", true)}
        value={formik.values.email}
        error={formik.errors.email && formik.touched.email ? formik.errors.email : ""}
        autoComplete={"email"}
      />
      <InputFieldWardrobe
        {...formik.getFieldProps("password")}
        name={"password"}
        id={"password"}
        placeholder={t(i18Enum.User_UserProfile_Label_Password)}
        label={"password"}
        spaceProp={{ width: [1], marginTop: [2], padding: ["10px 0"] }}
        value={formik.values.password}
        isPrivate={true}
        error={formik.errors.password && formik.touched.password ? formik.errors.password : ""}
        onBlur={() => formik.setFieldTouched("password", true)}
        onChange={formik.handleChange}
        autoComplete={"current-password"}
      />
      <Button variant='login' type='button' marginTop={[4]} disabled={!formik.isValid || formik.errors.email || formik.errors.password ? true : false} onClick={() => formik.handleSubmit()}>
        {t(i18Enum.Login_LoginButton)}
      </Button>
    </Form>
  );
}

export default LoginForm;
