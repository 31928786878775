import { ProductType, ShopDetailInProductDTO } from "redux/features/product/ProductsTypes";
import { Pager } from "../../api/types/responses";

export const PagerInitialState: Pager = {
  PageNum: 1,
  PageSize: 0,
  TotalItems: 0,
  TotalPages: 0,
  Links: [],
};

export const shopInitialState: ShopDetailInProductDTO = {
  Id: "",
  Description: "",
  Name: "",
  Picture: "",
  About: "",
  CoverPicture: "",
  ShopLocation: "",
  Code: "",
};

export const productInitialState: ProductType = {
  Id: "",
  Published: false,
  LikesCount: 0,
  Name: "",
  ShopId: "",
  AddedDate: new Date(),
  Code: "",
  Description: "",
  Gtin: "",
  MetatagDescription: "",
  MetatagKeywords: "",
  MetatagTitle: "",
  Sku: "",
  Tags: [],
  ShowInBsportal: false,
  ExternalLink: "",
  AddedBy: "",
  LastModifiedDate: "",
  EditorChoice: false,
  PublishDate: "",
  Composition: "",
  Delivery: "",
  ShortDescription: "",
  Order: 0,
  Shop: shopInitialState,
  Variants: [],
};
