import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Txt from "../components/primitives/Txt";
import Flex from "../components/primitives/Flex";
import Button from "../components/primitives/Button";
import BackgroundVideo from "../components/molecules/VideoBackgroud";
import RegisterForm from "../components/organisms/RegisterForm";
import ArrowSvg from "../components/atoms/ArrowSvg";
import { CustomThemeContext } from "context/ThemeContext";
import { useAppSelector } from "redux/app/hooks";
import { useTranslation } from "react-i18next";
import { i18Enum } from "i18n/types/translationType";

type variant = "form" | "navigate";

export const RegisterPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [variant, setVariant] = useState<variant>("form");
  const { customTheme } = useContext(CustomThemeContext);
  const { isSuccess } = useAppSelector((state) => state.register.register);

  useEffect(() => {
    if (!isSuccess) {
      setVariant("form");
      return;
    } else {
      setVariant("navigate");
    }
  }, [isSuccess]);

  const goToVerification = () => {
    navigate("/verify");
  };

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <>
      <Flex
        width={[1]}
        height={["100%"]}
        maxHeight={["100vh"]}
        maxWidth={customTheme.maxWidth[0]}
        position={"relative"}
        alignItems='center'
        marginTop={["100px", 0]}
        justifyContent={variant === "form" ? "left" : "right"}
      >
        {variant === "form" ? (
          <>
            <Flex
              flexDirection={"column"}
              alignItems={"start"}
              justifyContent={["normal", "center"]}
              minHeight={"calc(100vh - 60px)"}
              width={[1, 1, 0.5]}
              padding={["40px 28px 40px"]}
              $gap={1.5}
              style={{ boxSizing: "border-box" }}
            >
              <ArrowSvg handleClick={handleBack} widthCustom='s' />
              <RegisterForm width={[1]} />
            </Flex>
          </>
        ) : (
          <Flex
            flexDirection={"column"}
            alignItems={"start"}
            justifyContent={["normal", "center"]}
            minHeight={"calc(100vh - 200px)"}
            width={[1, 1, 0.5]}
            padding={["40px 28px 40px"]}
            $gap={4}
            style={{ boxSizing: "border-box" }}
          >
            <Txt width={[1]} variant='light' fontSize={[3]} textAlign={"end"}>
              {`✉️ ${t(i18Enum.EmailConfirmation_SendEmail)} ✉️`}
            </Txt>
            <Button variant='login' type='button' onClick={goToVerification}>
              {t(i18Enum.Register_ValidateButton)}
            </Button>
          </Flex>
        )}
      </Flex>
      <BackgroundVideo video='first' variant={variant === "form" ? "left" : "right"} height={"100vh"} top={0} width={[0.9, 0.9, 0.5]} />
    </>
  );
};
