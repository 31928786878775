import authReducer from '../features/auth/authSlice'
import userReducer from '../features/user/userSlice'
import modalReducer from '../features/modal/modalSlice'
import productReducer from 'redux/features/product/productSlice';
import wardrobeReducer from 'redux/features/wardrobe/wardrobeSlice'
import cartReducer  from 'redux/features/cart/cartSlice'
import brandReducer from'redux/features/brand/brandSlice'
import registerReducer from 'redux/features/register/registerSlice'
import outfitsReducer from'redux/features/outfits/outfitsSlice'
import communityReducer from 'redux/features/community/communitySlice'

// This code has a filename of src/redux/app/rootReducer.ts and is written in Type
  const rootReducers = {
    // Reducers here
    auth:authReducer,
    user:userReducer,
    modal:modalReducer,
    product:productReducer,
    wardrobe:wardrobeReducer,
    cart:cartReducer,
    brand:brandReducer,
    register:registerReducer,
    outfits:outfitsReducer,
    community:communityReducer
  };
  
  export default rootReducers;