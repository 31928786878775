import { useContext, useEffect } from "react";
import { CustomThemeContext } from "../../context/ThemeContext";
import { MultiColorKeyGradient, VariantColor, colorsKeyEnum, priceNum, truncate } from "../../utils";
import Flex from "../primitives/Flex";
import Txt from "../primitives/Txt";
import Button from "../primitives/Button";
import CartSvg from "../atoms/CartSvg";
import SelectSize from "./SelectSize";
import SelectColor from "./SelectColor";
import { FlexboxProps, SpaceProps } from "styled-system";
import CrossSvg from "../atoms/CrossSvg";
import { ProductType, VariantDTO } from "redux/features/product/ProductsTypes";
import { SelectedProduct } from "utils/generalTypes/Frontend";
import { i18Enum } from "i18n/types/translationType";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "redux/app/hooks";
import LoadingSpinner from "./LoadingSpinner";
import Tooltip from "components/primitives/Tooltip";

interface CardSelectedProps extends SpaceProps, FlexboxProps {
  productVariants?: VariantDTO[] | null;
  card?: VariantDTO | null | undefined;
  product?: ProductType;
  selected?: VariantDTO | null;
  handleContinue?: () => void;
  handleSelection: (product?: VariantDTO) => void;
  handleClose?: () => void;
  productToBasket: (selectedProduct: SelectedProduct) => void;
}

function CardSelected({ card, product, selected, handleContinue, handleSelection, handleClose, productToBasket, ...props }: CardSelectedProps) {
  const cartIsUpdating = useAppSelector((state) => state.cart.cart.isLoading);
  const isBuyNow = useAppSelector((state) => state.cart.cart.data?.isBuyNow);
  const { customTheme } = useContext(CustomThemeContext);

  const colorsTheme = customTheme.colors;
  const { t } = useTranslation();

  useEffect(() => {
    if (!props.productVariants?.length && selected !== null) {
      handleSelection(selected);
    }
  }, []);

  const showColorSection = props.productVariants?.some((variant) => variant?.Colors && variant?.Colors?.length > 0) ?? false;

  return (
    <Flex position={"relative"} height={["100%"]} width={[1]} flexDirection={"column"} justifyContent='space-between' alignItems='start' padding={["19px 11px"]} $gap={card ? 0.5 : 1} {...props}>
      {handleClose && <CrossSvg position={"absolute"} top={"0.6em"} right={"0.6em"} width={"15px"} handleClick={handleClose} />}

      {card && product && (
        <>
          <Txt
            variant='light'
            color={colorsTheme.thirdText}
            fontSize={[0]}
            /*  marginBottom={"8px"} */
          >
            {product.Shop.Name}
          </Txt>

          <Txt as={"h3"} variant='medium' color={colorsTheme.thirdText} fontSize={[2]} textAlign={"left"} lineHeight={"inherit"}>
            {truncate(product?.Name ?? "", 38)}
          </Txt>
        </>
      )}

      {showColorSection && (
        <Flex width={[1]} justifyContent={card ? "space-between" : "left"} alignItems={"center"} opacity={1 /**colors.length === 1 ? 0 : 1**/} $gap={1.5}>
          <Txt variant='light' color={colorsTheme.thirdText} fontSize={[1]}>
            {t(i18Enum.Product_SelectColor)}
          </Txt>
          <Flex $gap={0.5} flexWrap={"wrap"}>
            {props.productVariants?.length || !selected ? (
              props.productVariants?.map((variant) => {
                return variant.Colors?.map((tag) => {
                  return (
                    <Tooltip content={tag.Label ?? ""} key={tag?.Id}>
                      <SelectColor
                        isBlack={tag?.Label === "Black"}
                        background={VariantColor(tag?.Label ?? "")}
                        backgroundImage={tag?.Label === colorsKeyEnum.Multicolour ? MultiColorKeyGradient : "unset"}
                        selected={selected?.Id === variant?.Id ? true : false}
                        onClick={() => handleSelection(variant)}
                      />
                    </Tooltip>
                  );
                });
              })
            ) : (
              <Tooltip content={selected.Tags?.find((tag) => tag.Label === "Color")?.Label ?? ""} key={selected?.Id}>
                <SelectColor
                  isBlack={selected.Tags?.find((tag) => tag.Label === "Color")?.Label === "Black"}
                  background={VariantColor(selected.Tags?.find((tag) => tag.Label === "Color")?.Label ?? "")}
                  backgroundImage={selected.Tags?.find((tag) => tag.Label === "Color")?.Label === colorsKeyEnum.Multicolour ? MultiColorKeyGradient : "unset"}
                  selected={true}
                  onClick={() => handleSelection(selected)}
                />
              </Tooltip>
            )}
          </Flex>
        </Flex>
      )}

      {props.productVariants?.length && (
        <SelectSize
          height={"auto"}
          variant={card ? "s" : "xl"}
          title={t(i18Enum.Product_SelectSize)}
          productVariants={props.productVariants}
          selected={selected}
          productToBasket={(product) => productToBasket(product)}
        />
      )}
      {card && (
        <Button width={[1]} height={"34px"} backgroundColor={colorsTheme.grayBase} disabled={!selected} onClick={handleContinue} padding={["1em", "0.4em 0"]}>
          <CartSvg width={"13px"} height={"17px"} />
          <Txt variant='medium' color={colorsTheme.thirdBase} fontSize={["10px", 2]} marginLeft={"10px"} paddingTop={["0", "3px"]}>
            {cartIsUpdating && !isBuyNow ? <LoadingSpinner dimension='30px' /> : t(i18Enum.Product_ProductCard_Button_AddToCart)}
          </Txt>
        </Button>
      )}

      {card && (
        <Flex $gap={0.5}>
          <Txt variant={card.DiscountedPrice ? "crossed" : "medium"} color={colorsTheme.thirdText} fontSize={[2]}>
            {card.Price && `${priceNum(card.Price)}€`}
          </Txt>

          {card.DiscountedPrice && (
            <Txt variant='medium' color={colorsTheme.primaryText} fontSize={[2]}>
              {`${priceNum(card.DiscountedPrice)}€`}
            </Txt>
          )}
        </Flex>
      )}
    </Flex>
  );
}

export default CardSelected;
