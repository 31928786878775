import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AUTH, AuthStateType } from "./AuthTypes";
import { LoginResponse } from "api/types/responses";
import { LoginRequest } from "api/types/requests";


const authInitialState: AuthStateType = {
    auth:{
        data:null,
        isLoading:false,
        errors:""
    }
}

const authSlice = createSlice({
     name:AUTH,
     initialState: authInitialState,
     reducers:{
        // auth/askAuth
        askAuth: (state: AuthStateType , {payload}: PayloadAction<LoginRequest>)=>{
            state.auth.isLoading = true;
            state.auth.errors= "";
        },
        askAuthSuccessAction: (state: AuthStateType , {payload}: PayloadAction<LoginResponse>)=>{
            state.auth.isLoading = false;
            state.auth.data = payload
            state.auth.errors= "";
        },
        askAuthErrorAction: (state: AuthStateType , {payload}: PayloadAction<string>)=>{
            state.auth.isLoading = false;
            state.auth.errors = payload;
        },
        removeAuthAction: (state: AuthStateType)=>{
            state.auth.isLoading = false;
            state.auth.errors= "";
            state.auth.data = null
        }
     }
})


/* const for easy access in components */
export const authActions = authSlice.actions
/*const for saga/store's  use */
export const {
    askAuth,
    askAuthSuccessAction,
    askAuthErrorAction,
    removeAuthAction,
} = authSlice.actions;

export default authSlice.reducer