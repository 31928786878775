import { useCallback, useContext, useState } from "react";
import Flex from "../components/primitives/Flex";
import SelectFilter from "../components/molecules/SelectFilter";
import Search from "../components/molecules/Search";
import Button from "../components/primitives/Button";
import Box from "../components/primitives/Box";
import { useForm } from "react-hook-form";
import { PAGE } from "utils/const/pagePath";
import { useNavigate } from "react-router-dom";
import { AppGeneralContext } from "context/AppGeneralContext";
import { NewFilter } from "components/organisms/NewFilter";
import { useAppDispatch } from "redux/app/hooks";
import { ProductActions } from "redux/features/product/productSlice";
import { ProductsListRequest } from "api/types/requests/productRequest";
import { ValueRangeSlider } from "components/molecules";
import { OrderBy } from "utils/generalTypes/shared";
import { Tags } from "redux/features/product/ProductsTypes";
import CrossSvg from "components/atoms/CrossSvg";
import Txt from "components/primitives/Txt";
import { useTheme } from "styled-components";
import { i18Enum } from "i18n/types/translationType";
import { useTranslation } from "react-i18next";
import Form from "components/primitives/Form";

interface SearchPageProps {
  handleBack?: () => void;
  close?: () => void;
}

export type FilterType = null | "default" | "categories" | "order" | "brand" | "material" | "size";

type Params = {
  ids?: string[];
  orderBy?: OrderBy;
  priceRange?: ValueRangeSlider;
  selectedTagsObj?: Tags[];
  isPromo?: boolean;
};
const SearchPageDesktop = ({ close, handleBack }: SearchPageProps) => {
  const { t } = useTranslation();
  //hook assignment
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { register, watch, reset } = useForm<{ search: "" }>();
  //context data
  const colors = useTheme().colors;
  const { setAsideIsOpen, filterList, isMobile, searchKeyword, setSearchKeyword } = useContext(AppGeneralContext);
  //useState data
  const [filterState, setFilterState] = useState<FilterType>(null);
  const [params, setParams] = useState<Params | undefined>({ ids: filterList?.OnHeader?.[0].Id ? [filterList?.OnHeader?.[0].Id] : [] });

  //handler functions
  const handleFilterState = useCallback((value: FilterType) => setFilterState(value), [filterState]);

  const handleToggleIds = (id: string, arr: string[]) => {
    if (arr?.includes(id)) {
      const index = arr.indexOf(id);
      arr?.splice(index, 1);
      setParams((prev) => ({ ...prev, ids: [...arr] }));
      return;
    }
    arr.push(id);
    setParams((prev) => ({ ...prev, ids: [...arr] }));
  };

  const handleToggleObj = (obj: Tags, objArr: Tags[]) => {
    const found = objArr.find((el) => el.Id === obj.Id);
    if (found) {
      const newArr = objArr.filter((el) => el.Id !== obj.Id);
      setParams((prev) => ({ ...prev, selectedTagsObj: [...newArr] }));
      return;
    }
    setParams((prev) => ({ ...prev, selectedTagsObj: [...objArr, obj] }));
  };

  const handleParamsIds = useCallback(
    (id?: string, obj?: Tags) => {
      if (!id || !obj?.Id) {
        return;
      }
      const arr: string[] = params?.ids ?? [];
      const objArr: Tags[] = params?.selectedTagsObj ?? [];
      handleToggleIds(id, arr);
      handleToggleObj(obj, objArr);
      return;
    },
    [params?.ids]
  );

  const handleBackLocal = (obj?: Tags[], orderBy?: OrderBy, priceRange?: ValueRangeSlider, isPromo?: boolean) => {
    if (filterState !== null) {
      setFilterState(null);
    }
    if (isMobile && filterState === null) {
      handleBack?.();
    }
    setParams((prev) => {
      const arr = obj?.map((tag) => tag.Id ?? "") ?? [];
      const prevIds = prev?.ids ?? [];
      const arrayWithoutDuplicates = [...new Set([...prevIds, ...arr])];
      return {
        ids: arrayWithoutDuplicates,
        orderBy: orderBy,
        priceRange: priceRange,
        selectedTagsObj: obj,
        isPromo: isPromo,
      };
    });
  };

  const normalizeOrderBy = (key: "orderBy" | "orderDirection", orderDirection?: string, orderBy?: string) => {
    if (!orderBy || !orderDirection) {
      return undefined;
    }
    if (key === "orderBy") {
      return orderBy;
    }
    if (key === "orderDirection") {
      return orderDirection;
    }
  };
  //search function
  const handleSearch = (keyword: string, params?: Params, isMobile?: boolean) => {
    setSearchKeyword(keyword);
    const orderBy = params?.orderBy?.OrderBy?.value;
    const orderDirection = params?.orderBy?.OrderDirection?.value;
    const req: ProductsListRequest = {
      Name: keyword ? keyword : undefined,
      IsFiltering: true,
      MinPrice: params?.priceRange?.minValue,
      MaxPrice: params?.priceRange?.maxValue,
      PageNum: 1,
      PageSize: 12,
      OrderDirection: normalizeOrderBy("orderDirection", orderDirection, orderBy),
      OrderBy: normalizeOrderBy("orderBy", orderDirection, orderBy),
      IsPromo: params?.isPromo,
      TagsIds: params?.ids ?? [],
    };
    dispatch(ProductActions.searchProductAction(req));
    if (isMobile) {
      close?.();
    } else {
      setAsideIsOpen(false);
    }
    navigate(PAGE.shopFiltered.menuPath, { state: { ...req, IsFiltering: false } });
  };

  const resetFilter = () => {
    reset({ search: "" });
    setParams(undefined);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      handleSearch(watch().search, params, isMobile);
      return;
    }
    if (e.ctrlKey && e.key === "a") {
      e.preventDefault();
      const inputElement = document.querySelector<HTMLInputElement>("#searchInput");
      if (inputElement) {
        inputElement.setSelectionRange(0, inputElement.value.length);
      }
      return;
    }
  };

  return (
    <Form as={"section"} width={[1]} paddingTop={[80, 0]} height={["100%"]} maxWidth={"500px"} minHeight={["100vh", "80vh"]} alignItems={"start"} justifyContent={"center"}>
      {filterState ? (
        <>
          <NewFilter
            handleBack={handleBackLocal}
            selectedFilters={params?.selectedTagsObj}
            selectedOrderBy={params?.orderBy}
            priceRangeSelected={params?.priceRange}
            isPromoSelected={params?.isPromo}
          />
        </>
      ) : (
        <Flex as={"ul"} width={[1]} height={["100%"]} flexDirection={"column"} justifyContent={"space-between"} alignItems={"center"} paddingRight={[3]} paddingLeft={[3]} paddingTop={["69px", 0, 0]}>
          <Flex as={"li"} width={[1]} flexDirection={"column"} justifyContent={"right"} $gap={1}>
            <SelectFilter variant={"gray"} filters={filterList?.OnHeader ?? []} selected={params?.ids} handleClick={(value: string) => handleToggleIds(value, params?.ids ?? [])} />
            <Search
              handleFilter={() => handleFilterState("default")}
              control={register("search")}
              value={searchKeyword}
              isFocused={true}
              placeholder={t(i18Enum.Common_Search)}
              launchSearch={() => handleSearch(watch().search, params, isMobile)}
              onKeyDown={(e) => handleKeyDown(e)}
              id='searchInput'
            />
            {(params?.ids && params.ids.length > 0) || params?.orderBy?.OrderDirection?.id ? (
              <Txt variant='linkDanger' color={colors.red10} as={"span"} fontFamily={"BauPro"} fontWeight={"400"} fontSize={[3]} onClick={resetFilter} paddingRight={[3, 2]} mb={2} textAlign={"right"}>
                {t(i18Enum.Filters_Label_Reset)}
              </Txt>
            ) : null}
          </Flex>
          <Flex flexDirection={"row"} flexWrap={"wrap"} paddingTop={2}>
            {params?.orderBy?.OrderDirection && (
              <Button mt={2} variant='switcherGenre' padding={"10px 20px"}>
                {params.orderBy.OrderDirection.label}
                <CrossSvg marginBottom={1} marginLeft={2} height={"10px"} width={"10px"} handleClick={() => setParams((prev) => ({ ...prev, orderBy: undefined }))} />
              </Button>
            )}
            {params?.selectedTagsObj?.map((el) => {
              return (
                <Button key={el.Id} marginTop={2} marginLeft={2} variant='switcherGenre' padding={"10px 20px"}>
                  {el.Label}
                  <CrossSvg marginBottom={1} height={"10px"} width={"10px"} marginLeft={2} handleClick={() => handleParamsIds(el.Id, el)} />
                </Button>
              );
            })}
            {params?.isPromo && (
              <Button mt={2} variant='switcherGenre' padding={"10px 20px"}>
                {t(i18Enum.Shopping_Page_Promo)}
                <CrossSvg marginBottom={1} marginLeft={2} height={"10px"} width={"10px"} handleClick={() => setParams((prev) => ({ ...prev, isPromo: false }))} />
              </Button>
            )}
          </Flex>
          <Box as={"li"} width={"min-content"} padding={"30px 0"}>
            <Button type='submit' variant={"success"} fontSize={[3]} fontWeight={500} width={["236px"]} height={["43px"]} onClick={() => handleSearch(watch().search, params, isMobile)}>
              {t(i18Enum.Common_Search)}
            </Button>
          </Box>
        </Flex>
      )}
    </Form>
  );
};

export default SearchPageDesktop;
