// import { getCall } from "api/baseCall";
import { getAriesCall, postAriesCall } from "api/baseAriesCall";
import { BrandsListRequest } from "api/types/requests/brandRequest";
import { BaseResponse, PaginatedResponse } from "api/types/responses/baseResponses";
import { BrandType } from "redux/features/brand/BrandTypes";

export const getBrandByShopIdCall = async (id: string) => {
  return await getAriesCall<BrandType>("Shop/" + id);
};

export const getBrandIdFromCode = async (shopCode: string) => await getAriesCall<BaseResponse<string>>(`Shop/GetByCode/${shopCode}`);

export const getBrandsListCall = async (req: BrandsListRequest) => await postAriesCall<BrandsListRequest, PaginatedResponse<BrandType>>("Shop/ShopsList", req);
