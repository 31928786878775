import Section from "components/primitives/Section";
import { Dispatch, ReactNode, SetStateAction, useContext, useEffect, useState } from "react";
import styled from "styled-components";
import Image from "components/primitives/Image";
import { ColorProps, flexbox, LayoutProps, SpaceProps, layout, space, FlexboxProps, position, PositionProps, border, BorderProps, color, compose, variant } from "styled-system";
import Flex from "components/primitives/Flex";
import CrossSvg from "./CrossSvg";
import { AppGeneralContext } from "context/AppGeneralContext";
import { CustomThemeContext } from "context/ThemeContext";
import { DotsLoader } from "components/molecules/DotsLoader";
import Txt from "components/primitives/Txt";
import { ConfirmDialog } from "components/molecules/ConfirmDialog";
import { i18Enum } from "i18n/types/translationType";
import { t } from "i18next";

const CartCardWrapper = styled.div<
  ColorProps &
    FlexboxProps &
    LayoutProps &
    SpaceProps &
    PositionProps &
    BorderProps & { $gap?: number } & { $aspectRatio?: string } & { $transition?: string } & { $backgroundImage?: string } & { variant?: "shipping" | "orders" | "default" | "success" | "error" }
>`
  position: relative;
  background: black;
  display: flex;
  flex-direction: row;
  &:nth-child(2n + 1) {
    flex-direction: row-reverse;
  }

  & > div {
    top: 0;
    justify-content: end;
  }

  &:nth-child(2n + 1) > div {
    top: 0;
    justify-content: start;
  }

  ${(props) =>
    compose(
      variant({
        variants: {
          default: {
            border: "1px solid white",
          },
          orders: {
            border: "1px solid white",
          },
          success: {
            border: `1px solid ${props.theme.colors.primaryBase}`,
          },
          error: {
            border: `1px solid ${props.theme.colors.dangerBase}`,
          },
        },
      })
    )}

  ${space}
  ${layout}
    ${flexbox}
    ${position}
    ${border}
    ${color}
`;

type CartCardBodyProps = {
  background: string;
  children: ReactNode;
  variant?: "default" | "success" | "error" | "orders" | "shipping";
  quantity?: number;
  deleteItem?: () => void;
  isLoading?: boolean;
  confirmMessage?: boolean;
  setConfirmMode?: Dispatch<SetStateAction<null | string>>;
};

export const CartCardBody = (props: CartCardBodyProps & ColorProps & FlexboxProps & LayoutProps & SpaceProps & PositionProps & BorderProps) => {
  const { isMobile } = useContext(AppGeneralContext);
  const { customTheme } = useContext(CustomThemeContext);
  const colors = customTheme.colors;
  const [confirmMessage, setConfirmMessage] = useState(props.confirmMessage);

  useEffect(() => {
    setConfirmMessage(props.confirmMessage);
  }, [props.confirmMessage]);

  return (
    <CartCardWrapper {...props} variant={props.variant} minHeight={isMobile ? "200px" : null} maxHeight={!isMobile ? "300px" : null} width={[1]}>
      {props.deleteItem && (
        <Flex as={"div"} position={"absolute"} width={[1]} paddingTop={3} paddingLeft={[2, 3]} paddingRight={[2, 3]}>
          {props.isLoading ? (
            <Flex height={isMobile ? "15px" : "20px"} width={isMobile ? "15px" : "20px"}>
              <DotsLoader colors='white' />
            </Flex>
          ) : (
            <CrossSvg
              height={isMobile ? "15px" : "20px"}
              handleClick={() => {
                setConfirmMessage(!confirmMessage);
                props.setConfirmMode && props.setConfirmMode(null);
              }}
            />
          )}
        </Flex>
      )}
      <Section position={"relative"} maxWidth={"35%"} width={[1]}>
        <Image src={props.background} width={[1]} $aspectRatio='1 / 1 ' objectFit='cover' />
        {(props.variant === "shipping" || props.variant === "orders") && (
          <Flex position={"absolute"} bottom={0} right={0} height={"50px"} width={"50px"} backgroundColor={colors.grayBgActive} alignItems={"center"} justifyContent={"center"}>
            {props.quantity}
          </Flex>
        )}
      </Section>
      <Section flexDirection={"column"} paddingTop={[4]} width={[1]} alignItems={"center"} justifyContent={"center"}>
        {confirmMessage ? (
          <Flex $gap={1} justifyContent={"center"} flexDirection={"column"} width={1} padding={["0% 5%", "5% 5% 4%"]} marginBottom={["0.1em", "0.15em"]}>
            <Txt fontSize={6} textAlign={["center"]}>
              ⚠️
            </Txt>
            <Txt fontSize={[3, 4]} color={colors.thirdBase} textAlign={["center"]}>
              {t(i18Enum.Message_ConfirmDeleteItem)}
            </Txt>
            <Flex justifyContent={"center"} alignItems={"center"}>
              <ConfirmDialog
                hide={() => {
                  setConfirmMessage(!confirmMessage);
                  props.setConfirmMode && props.setConfirmMode(null);
                }}
                deleteCallback={() => {
                  props?.deleteItem ? props?.deleteItem() : null;
                }}
              />
            </Flex>
          </Flex>
        ) : (
          props.children
        )}
      </Section>
    </CartCardWrapper>
  );
};
