import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AppGeneralContext } from "../context/AppGeneralContext";
import Section from "../components/primitives/Section";
import Flex from "../components/primitives/Flex";
import Txt from "../components/primitives/Txt";
import { UserAvatarAndFollowers } from "../components/molecules/UserAvatarAndFollowers";
import { toastType } from "../utils";
import Button from "../components/primitives/Button";
import ArrowSvg from "../components/atoms/ArrowSvg";
import { CustomThemeContext } from "../context/ThemeContext";
import Box from "../components/primitives/Box";
import { SocialIcon } from "../components/atoms/SocialIcon";
// import { WardrobeItemType } from "utils/generalTypes/wardrobe";
import { useAppSelector } from "redux/app/hooks";
import { UserType } from "redux/features/user/UserTypes";
import { ApiSimpleResponse, BaseResponse } from "api/types/responses";
import { getUserByIDCall } from "api/userCalls/userCalls";
import { i18Enum } from "i18n/types/translationType";
import { useTranslation } from "react-i18next";

export const ProfilePreview = () => {
  const { addToast, setIsLoading } = useContext(AppGeneralContext);
  const { customTheme } = useContext(CustomThemeContext);
  const navigate = useNavigate();
  const colors = customTheme.colors;
  const { id } = useParams();
  const [userData, setUserData] = useState<UserType>();
  // const [cardsData, setCardsData] = useState<WardrobeItemType[][]>();
  const user = useAppSelector((state) => state.user.user.data);
  const auth = useAppSelector((state) => state.auth.auth.data?.Data);
  const { t } = useTranslation();

  useEffect(() => {
    if (!id) {
      return;
    }
    getProfile(id);
  }, [id]);

  async function getProfile(userId: string) {
    setIsLoading(true);
    const res: ApiSimpleResponse<BaseResponse<UserType>> = await getUserByIDCall(userId);
    if (!res.isSuccess || !res.response?.Data) {
      addToast(toastType.error, res.error ?? t(i18Enum.Error_MissingUser));
      setIsLoading(false);
      return;
    }

    setUserData(res.response.Data);
    setIsLoading(false);
  }
  // TODO riabilitare quando esiste wardrobe
  // useEffect(() => {
  //   if (!wardrobeItems?.Data)
  //     return

  //   const arrFromWardrobeItems:WardrobeItemType[]= wardrobeItems.items.map(parseWardrobeItems)
  //   const cardsArray: WardrobeItemType[][] = [];
  //   sortCards(arrFromWardrobeItems, cardsArray, isMobile ? 4 : 8);
  //   setCardsData(cardsArray);
  //  }, [isMobile, wardrobeItems?.items]);

  return (
    <Box marginBottom={"5%"} padding={["12% 0", "5% 0", "0"]}>
      <Section flexDirection={"column"} padding={"2em"} width={"100%"}>
        <ArrowSvg widthCustom='s' position={"absolute"} left={[3]} handleClick={() => navigate(-1)} />
      </Section>

      <Flex justifyContent={"center"}>
        {userData?.Id && (
          <Section key={userData?.Id} flexDirection={"column"} padding={["0 2em", "0 5em"]} minWidth={[300, 768, 950]} maxWidth={950} width={"100%"} $gap={2}>
            <Flex flexDirection={"column"} alignItems={"center"} $gap={1.5}>
              <Box width={["100%", "60%"]} marginBottom={3}>
                <UserAvatarAndFollowers
                  avatar={userData?.PictureUrl ?? null}
                  firstText={t(i18Enum.Common_Followers)}
                  followers={userData?.FollowersCount ?? 0}
                  secondText={t(i18Enum.Common_Following)}
                  following={userData?.FollowingCount ?? 0}
                />
              </Box>

              {userData.Name && (
                <Box width={["100%", "60%"]}>
                  <Txt textAlign={"left"} as={"h3"} variant='medium' color={colors.primaryBase} fontSize={[3]} margin={0}>{`${userData.Name}`}</Txt>
                </Box>
              )}

              {userData.ShortDescription && (
                <Box width={["100%", "60%"]}>
                  <Txt as='p' variant='light' fontSize={[3, 2]} color={colors.thirdText} textAlign={"left"} $textTransform='inherit' margin={0}>
                    {userData.ShortDescription}
                  </Txt>
                </Box>
              )}

              {!userData.FacebookUrl && !userData.InstagramUrl && !userData.TikTokUrl && !userData.TwitterUrl && !userData.YouTubeUrl && !userData.PinterestUrl && (
                <Flex width={["100%", "60%"]} $gap={2}>
                  {userData.FacebookUrl && <SocialIcon variant='facebook' url={userData.FacebookUrl} />}
                  {userData.InstagramUrl && <SocialIcon variant='instagram' url={userData.InstagramUrl} />}
                  {userData.TikTokUrl && <SocialIcon variant='tiktok' url={userData.TikTokUrl} />}
                  {userData.TwitterUrl && <SocialIcon variant='twitter' url={userData.TwitterUrl} />}
                  {userData.YouTubeUrl && <SocialIcon variant='youtube' url={userData.YouTubeUrl} />}
                  {userData.PinterestUrl && <SocialIcon variant='pinterest' url={userData.PinterestUrl} />}
                </Flex>
              )}

              <Flex width={["100%", "60%"]}>
                <Button disabled={auth && user?.Id === id ? true : false} type='button' variant='primary' padding={["12px 20px 9px"]} fontSize={[3, 2]} width={"100%"}>
                  {`${t(i18Enum.Common_Follow)} ${userData.GivenName}`}
                </Button>
              </Flex>
            </Flex>

            {/* TODO: gestire wardrobe {visibility[4].isPublic && (
              <Flex flexDirection={"column"} width={"100%"} $gap={1.5}>
                <Box marginBottom={2}>
                  <Txt as={"h3"} variant='light' color={colors.thirdText} textAlign={"left"} marginBottom={0}>
                    Wardrobe
                  </Txt>
                </Box>

                <Search placeholder='FIND' />

                <Flex flexDirection={"column"} $gap={0.75}>
                  <Flex flexDirection={["column", "row"]} flexWrap={"wrap"} justifyContent={"center"} alignItems={"center"}>
                    {cardsData?.length && cardsData.map((cards, index) => {
                      return (
                        <Flex key={index} flexWrap={"wrap"}>
                          <WardrobeTemplate isFirst={index === 0 ? true : false} hasEdit={false} data={cards} />
                        </Flex>
                      );
                    })}
                  </Flex>
                </Flex>
              </Flex>
            )} */}
          </Section>
        )}
      </Flex>
    </Box>
  );
};
