import { Navigate, Route, createBrowserRouter, createRoutesFromElements } from "react-router-dom";
import {
  Layout,
  LoginPage,
  ProtectedPage,
  PublicPage,
  Profile,
  ProfilePreview,
  HomePage,
  WardrobeAddWear,
  RegisterPage,
  Cart,
  ShippingMethod,
  ShippingAddress,
  OrderSent,
  AddressPage,
  AccountPage,
  CommunityPage,
  TermsAndConditions,
  PrivacyPolicy,
  CookiePolicy,
  PaymentOptions,
  Shipping,
  ReturnedGoods,
  AboutUs,
  Affiliate,
  AffiliatePolicy,
  VerifyUserPage,
  ForgotPswPage,
  BrandsList,
} from "../pages";

import { SingleProduct } from "../pages/SingleProduct";
import { PAGE } from "../utils/const/pagePath";
import Shop from "pages/Shop";
import ShopFiltered from "pages/ShopFiltered";

import WishlistPage from "pages/WishlistPage";
import { Wardrobe } from "pages/Wardrobe";
import OrdersPage from "pages/OrdersPage";
import { Brand } from "pages/Brand";
import { OutFitPage } from "pages/OutFitPage";
import { BuyOutfit } from "pages/BuyOutfit";
import { OrdersDetailsPage } from "pages/OrdersPageDetails";

import { AddressPageEdit } from "pages/AddressPageEdit";
import { BillingPageEdit } from "pages/BillingPageEdit";
import SearchPageDesktop from "pages/SearchPageDesktop";
import { ProfilingPage } from "pages/ProfilingPage";
import { MagazinePage } from "pages/MagazinePage";
import { ResponsePage } from "pages/ResponsePage";
import { NotFound } from "pages/NotFound";
import { PendingPaymentPage } from "pages/PendingPaymentPage";
import { OrdersParentDetailsPage } from "pages/OrdersParentPageDetails";

export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path='/' element={<Layout />} errorElement={<NotFound />}>
      <Route path='/' element={<Navigate to={PAGE.home.path} />} />

      <Route path='/' element={<PublicPage />}>
        <Route path={PAGE.home.path} element={<HomePage />} />
        <Route path={`${PAGE.home.path}/*`} element={<Navigate to={PAGE.home.menuPath} />} />
        <Route path={PAGE.termsAndConditions.path} element={<TermsAndConditions />} />
        <Route path={PAGE.privacyPolicy.path} element={<PrivacyPolicy />} />
        <Route path={PAGE.cookiePolicy.path} element={<CookiePolicy />} />
        <Route path={PAGE.payment.path} element={<PaymentOptions />} />
        <Route path={PAGE.shippings.path} element={<Shipping />} />
        <Route path={PAGE.returnedGoods.path} element={<ReturnedGoods />} />
        <Route path={PAGE.aboutUs.path} element={<AboutUs />} />
        <Route path={PAGE.affiliateProgram.path} element={<Affiliate />} />
        <Route path={PAGE.affiliatePolicy.path} element={<AffiliatePolicy />} />
        <Route path={PAGE.profilePreview.path} element={<ProfilePreview />} />
        <Route path={"shop"}>
          <Route index path={PAGE.shopList.path} element={<Shop />} />
          <Route path={PAGE.shopFilter.path} element={<SearchPageDesktop />} />
          <Route path={PAGE.shopFiltered.path} element={<ShopFiltered />} />
          <Route path={`${PAGE.product.path}/:code`} element={<SingleProduct />} />
          <Route path={`${PAGE.brand.path}/:code`} element={<Brand />} />
          <Route path={PAGE.brandsList.path} element={<BrandsList />} />
        </Route>
        <Route path='outfit'>
          <Route index path={PAGE.outfit.path} element={<OutFitPage />} />
          <Route path={`${PAGE.buyOutfit.path}/:id`} element={<BuyOutfit />} />
        </Route>
      </Route>

      <Route path='login' element={<LoginPage />} />
      <Route path='register' element={<RegisterPage />} />
      <Route path='verify' element={<VerifyUserPage />} />
      <Route path='forgot-password' element={<ForgotPswPage />} />
      {/* cart */}
      <Route path={"cart"}>
        <Route index path={PAGE.cart.path} element={<Cart />} />
        <Route path={PAGE.shipping.path} element={<ShippingMethod />} />
        <Route path={PAGE.shippingAddress.path} element={<ShippingAddress />} />
      </Route>
      <Route path={"payment"}>
        <Route path={PAGE.paymentSuccess.menuPath} element={<ResponsePage isSuccess />} />
        <Route path={PAGE.paymentError.menuPath} element={<ResponsePage isSuccess={false} />} />
        <Route path={`${PAGE.paymentPending.menuPath}/:url`} element={<PendingPaymentPage />} />
      </Route>
      {/* user*/}
      <Route path='user' element={<ProtectedPage />}>
        <Route index path={PAGE.profile.path} element={<Profile />} />
        <Route index path={PAGE.firstLogin.path} element={<ProfilingPage />} />
        <Route path='address'>
          <Route path={PAGE.address.path} element={<AddressPage />} />
          <Route path={PAGE.addressEdit.path} element={<AddressPageEdit />} />
        </Route>
        <Route path={PAGE.account.path} element={<AccountPage />} />
        <Route path={"wardrobe"}>
          <Route index path={PAGE.wardrobe.path} element={<Wardrobe />} />
          <Route path={PAGE.wardrobeEdit.path} element={<WardrobeAddWear />} />
        </Route>
        <Route path={PAGE.wishlist.path} element={<WishlistPage />} />
        <Route path={"orders"}>
          <Route index path={PAGE.orders.path} element={<OrdersPage />} />
          <Route path={PAGE.ordersDetail.path} element={<OrdersDetailsPage />} />
          <Route path={PAGE.ordersParentDetail.path} element={<OrdersParentDetailsPage />} />
          <Route path={PAGE.billing.path} element={<BillingPageEdit />} />
        </Route>
        <Route path={"orderSent"} element={<OrderSent />} />
      </Route>
      <Route path={PAGE.outfit.path} element={<OutFitPage />} />
      <Route path={PAGE.community.path} element={<CommunityPage />} />
      <Route path={PAGE.magazine.path} element={<MagazinePage />} />
      <Route path={PAGE.notFound.menuPath} element={<NotFound />} />
    </Route>
  )
);
