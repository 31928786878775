import { useContext, useEffect, useState } from "react";
import { SpaceProps, WidthProps } from "styled-system";
import { CustomThemeContext } from "../../context/ThemeContext";
import { capitalize } from "../../utils";

import Flex from "../primitives/Flex";
import Txt from "../primitives/Txt";
import Button from "../primitives/Button";
import Checkbox from "../molecules/Checkbox";
import * as Yup from "yup";
import InputFieldWardrobe from "components/molecules/InputFieldWardrobe";
import Form from "components/primitives/Form";
import { useFormik } from "formik";
import YupPassword from "yup-password";
import { AppGeneralContext } from "context/AppGeneralContext";
import { useAppDispatch, useAppSelector } from "redux/app/hooks";
import { RegisterActions } from "redux/features/register/registerSlice";
import { RegisterRequest } from "api/types/requests/registerRequest";
import { PAGE } from "utils/const/pagePath";
import { i18Enum } from "i18n/types/translationType";
import { useTranslation } from "react-i18next";

YupPassword(Yup);

export interface FormRegister {
  Email: string;
  Password: string;
  ConfirmPassword: string;
  GivenName: string;
  FamilyName: string;
  IsMarektingPolicyAgree: boolean;
  IsPrivacyPolicyAgree: boolean;
  IsProfilingPolicyAgree: boolean;
  IsTermsOfUseAgree: boolean;
}

type RegisterFormSchemaObject = {
  [key in keyof FormRegister]: Yup.Schema<any>;
};
interface FormField {
  id: keyof Pick<FormRegister, "Email" | "Password" | "ConfirmPassword" | "GivenName" | "FamilyName">;
  placeholder: string;
  isPrivate?: boolean;
  width: number[];
}
interface CheckboxData {
  id: keyof Pick<FormRegister, "IsMarektingPolicyAgree" | "IsPrivacyPolicyAgree" | "IsProfilingPolicyAgree" | "IsTermsOfUseAgree">;
  label: string;
  linkLabel?: string;
  onClick?: () => void;
}

const registerFormInitialValues: FormRegister = {
  Email: "",
  Password: "",
  ConfirmPassword: "",
  GivenName: "",
  FamilyName: "",
  IsMarektingPolicyAgree: false,
  IsPrivacyPolicyAgree: false,
  IsProfilingPolicyAgree: false,
  IsTermsOfUseAgree: false,
};

interface RegisterFormProps extends WidthProps, SpaceProps {}

function RegisterForm({ ...props }: RegisterFormProps) {
  const { customTheme } = useContext(CustomThemeContext);
  const { setIsLoading, setUserId } = useContext(AppGeneralContext);
  const colors = customTheme.colors;
  const dispatch = useAppDispatch();
  const [isValid, setIsValid] = useState(false);
  const { isLoading: isFetchingRegister, isSuccess, data } = useAppSelector((state) => state.register.register);
  const { t } = useTranslation();

  const formBillingSchema = Yup.object().shape<RegisterFormSchemaObject>({
    Email: Yup.string().matches(/^[\w\-.]+@([\w-]+\.)+[a-zA-Z]{2,10}$/i, t(i18Enum.Validation_Client_Field_Email)).required(t(i18Enum.Validation_Client_Field_Required)),
    GivenName: Yup.string(),
    FamilyName: Yup.string(),
    Password: Yup.string()
      .password()
      .required(t(i18Enum.Validation_Client_Field_Required))
      .min(10, t(i18Enum.Login_Password_ValidationFailed, { value: "10" }))
      .minLowercase(1)
      .minUppercase(1)
      .minNumbers(1)
      .minSymbols(1),
    ConfirmPassword: Yup.string()
      .oneOf([Yup.ref("Password")], t(i18Enum.Login_PasswordsMustMatch))
      .required(t(i18Enum.Validation_Client_Field_Required)),
    IsMarektingPolicyAgree: Yup.bool(),
    IsProfilingPolicyAgree: Yup.bool(),
    IsPrivacyPolicyAgree: Yup.boolean().required().oneOf([true], t(i18Enum.Validation_Client_Field_Required)),
    IsTermsOfUseAgree: Yup.boolean().required().oneOf([true], t(i18Enum.Validation_Client_Field_Required)),
  });


  const formFields: FormField[] = [
    {
      id: "GivenName",
      placeholder: t(i18Enum.User_UserProfile_Label_GivenName),
      width: [1, 1, 1, 0.49],
    },
    {
      id: "FamilyName",
      placeholder: t(i18Enum.User_UserProfile_Label_FamilyName),
      width: [1, 1, 1, 0.49],
    },
    {
      id: "Email",
      placeholder: t(i18Enum.User_UserProfile_Label_Email),
      width: [1, 1, 1],
    },
    {
      id: "Password",
      placeholder: t(i18Enum.User_UserProfile_Label_Password),
      isPrivate: true,
      width: [1, 1, 1, 0.49],
    },
    {
      id: "ConfirmPassword",
      placeholder: t(i18Enum.Login_PasswordConfirmation),
      isPrivate: true,
      width: [1, 1, 1, 0.49],
    },
  ];

  const checkboxesData: CheckboxData[] = [
    {
      id: "IsTermsOfUseAgree",
      label: t(i18Enum.IsTermsOfUseAgree),
      linkLabel: t(i18Enum.Footer_TermsAndConditions),
      onClick: () => {
        window.open(PAGE.termsAndConditions.menuPath, "_blank", "noopener,noreferrer");
      },
    },
    {
      id: "IsPrivacyPolicyAgree",
      label: t(i18Enum.IsPrivacyPolicyAgree),
      linkLabel: t(i18Enum.Footer_PrivacyPolicy),
      onClick: () => {
        window.open(PAGE.privacyPolicy.menuPath, "_blank", "noopener,noreferrer");
      },
    },
    {
      id: "IsMarektingPolicyAgree",
      label: t(i18Enum.IsMarektingPolicyAgree),
    },
    {
      id: "IsProfilingPolicyAgree",
      label: t(i18Enum.IsProfilingPolicyAgree),
    },
  ];
  useEffect(() => {
    setIsLoading(isFetchingRegister);
  }, [isFetchingRegister]);

  useEffect(() => {
    setUserId(data);
  }, [data])

  const formik = useFormik<FormRegister>({
    enableReinitialize: true,
    initialValues: registerFormInitialValues,
    validationSchema: formBillingSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      const newReq: RegisterRequest = {
        Email: values.Email,
        Password: values.Password,
        GivenName: values.GivenName,
        FamilyName: values.FamilyName,
        IsMarektingPolicyAgree: values.IsMarektingPolicyAgree,
        IsPrivacyPolicyAgree: values.IsPrivacyPolicyAgree,
        IsProfilingPolicyAgree: values.IsPrivacyPolicyAgree,
        IsTermsOfUseAgree: values.IsTermsOfUseAgree,
      };
      dispatch(RegisterActions.getRegisterAction(newReq));
      if (!isSuccess) {
        setSubmitting(false);
        return;
      }
      setUserId(data)
      setSubmitting(false);
      formik.resetForm();
    },
  });

  useEffect(() => {
    if (formik.isValid && Object.keys(formik.touched).length > 0 && Object.keys(formik.errors).length === 0) {
      setIsValid(true);
      return;
    }
    setIsValid(false);
  }, [formik.isValid, formik.touched, formik.errors]);

  return (
    <Form as='form' flexDirection={"column"} onSubmit={formik.handleSubmit} alignItems={"end"} {...props}>
      <Txt as='legend' width={[1]} fontSize={[4]} variant='light' textAlign={"left"}>
        {t(i18Enum.Login_CreateAccount)}
      </Txt>
      <Flex flexWrap={"wrap"} width={[1]} justifyContent={"space-between"}>
        {formFields.map((field) => {
          const fieldName = field.id;
          const autoComplete = fieldName === "Email" ? "email" : fieldName === "GivenName" ? "given-name" : fieldName === "FamilyName" ? "family-name" : "new-password";
          const error = formik.errors[fieldName] && formik.touched[fieldName] ? String(formik.errors[fieldName]) : undefined;
          return (
            <InputFieldWardrobe
              {...formik.getFieldProps(fieldName)}
              key={fieldName}
              spaceProp={{ width: field.width, marginTop: [2], padding: ["10px 0"] }}
              id={fieldName}
              type={fieldName === "Email" ? "email" : "text"}
              autoComplete={autoComplete}
              isPrivate={field.isPrivate}
              placeholder={field.placeholder}
              value={formik.values[fieldName]}
              name={fieldName}
              label={field.placeholder}
              onChange={formik.handleChange}
              onBlur={() => formik.setFieldTouched(fieldName, true)}
              error={error}
            />
          );
        })}
      </Flex>

      {checkboxesData.map((checkbox, index) => {
        const fieldName = checkbox.id;
        const error = formik.errors[fieldName] && formik.values[fieldName] === false && formik.touched[fieldName] ? String(formik.errors[fieldName]) : undefined;

        return (
          <Checkbox
            {...formik.getFieldProps(fieldName)}
            key={checkbox.id}
            spaceProp={{ width: [1], marginTop: index === 0 ? "38px" : "19px" }}
            id={checkbox.id}
            name={fieldName}
            checked={formik.values[fieldName]}
            onChange={formik.handleChange}
            onClick={() => formik.setFieldTouched(fieldName, true)}
            error={error}
            children={
              <Txt variant='light' fontSize={[1]} $textTransform='inherit'>
                {capitalize(checkbox.label)}
                {checkbox.linkLabel && (
                  <Txt paddingLeft={[1]} variant='linkLight' color={colors.primaryBg} onClick={checkbox.onClick}>
                    {capitalize(checkbox.linkLabel)}
                  </Txt>
                )}
              </Txt>
            }
          />
        );
      })}
      {/* <Checkbox
        key={'IsTermsOfUseAgree'}
        spaceProp={{ width: [1], marginTop: "38px"}}
        id={'IsTermsOfUseAgree'}
        name={'IsTermsOfUseAgree'}
        checked={formik.values['IsTermsOfUseAgree']}
        onChange={(e) => { console.log(e), formik.handleChange(e); formik.setFieldTouched('IsTermsOfUseAgree', true) }}
        error={(formik.errors['IsTermsOfUseAgree'] && formik.touched['IsTermsOfUseAgree']) ? String(formik.errors['IsTermsOfUseAgree']) : undefined}
      >
      </Checkbox> */}
      <Button variant='register' type='submit' marginTop={[4]} disabled={!isValid}>
        {t(i18Enum.Login_RegisterButton)}
      </Button>
    </Form>
  );
}

export default RegisterForm;
