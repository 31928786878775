import { useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import { PopupContent } from "components/molecules/PopupContent";
import Flex from "components/primitives/Flex";
import ArrowSvg from "components/atoms/ArrowSvg";
import { HomepagePopupDTO } from "utils/generalTypes/popupSlider";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

interface popupSliderProps {
  data: HomepagePopupDTO[];
}

export const PopupSlider = ({ data }: popupSliderProps) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const ref = useRef<any>();
  const [currentId, setCurrentId] = useState<string>(data[0].Id);
  const videoInitialState = data.reduce((prev, item) => {
    return {
      ...prev,
      [item.Id]: true,
    };
  }, {} as { [key: string]: boolean });
  const [isVideoMuted, setIsVideoMuted] = useState<{ [key: string]: boolean }>(videoInitialState);

  useEffect(() => {
    console.log(isVideoMuted);
  }, [isVideoMuted]);

  const handleSlideChange = (id: string) => {
    setCurrentId(id);
    setIsVideoMuted(videoInitialState);
  };

  const handleToggleMute = (id: string, isMuted: boolean) => {
    setIsVideoMuted((prevState) => ({
      ...prevState,
      [id]: isMuted,
    }));
  };

  return (
    <Flex width={"100%"} alignItems={["stretch"]}>
      {data.length > 1 && (
        <ArrowSvg
          top={["50%"]}
          $transition={"opacity 0.5s ease"}
          position={"absolute"}
          zIndex={20}
          left={["5vw", "2vw", "2vw", "1vw"]}
          handleClick={() => {
            ref.current?.slidePrev();
            setIsVideoMuted(videoInitialState);
          }}
        />
      )}
      <Swiper
        onSwiper={(el) => (ref.current = el)}
        modules={[Navigation]}
        slidesPerView={1}
        spaceBetween={20}
        loop={data && data.length > 1 ? true : false}
        autoplay={{
          stopOnLastSlide: false,
        }}
        onSlideChange={(swiper) => {
          const activeSlideIndex = swiper.activeIndex;
          const activeSlide = data[activeSlideIndex];
          handleSlideChange(activeSlide.Id);
        }}
      >
        {data?.length &&
          data.map((image) => (
            <SwiperSlide key={image.Id}>
              <PopupContent item={image} id={image.Id} isVideoMuted={isVideoMuted[image.Id]} onToggleMute={handleToggleMute} />
            </SwiperSlide>
          ))}
      </Swiper>
      {data.length > 1 && (
        <ArrowSvg
          top={["50%"]}
          $transition={"opacity 0.5s ease"}
          position={"absolute"}
          zIndex={20}
          $transform={"rotate(180deg)"}
          right={["5vw", "2vw", "2vw", "1vw"]}
          handleClick={() => {
            ref.current?.slideNext();
            setIsVideoMuted(videoInitialState);
          }}
        />
      )}
    </Flex>
  );
};
