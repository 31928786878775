import {
  StylesConfig,
  components,
  ControlProps,
  OptionProps,
} from "react-select";
import { ReactNode, useContext } from "react";
import { CustomThemeContext } from "../../context/ThemeContext";
import { ThemeType } from "../../theme/type/styled";
import { WidthProps } from "styled-system";

export const theme = (): ThemeType => {
  const { customTheme } = useContext(CustomThemeContext);
  return customTheme;
};

export interface IdOptionType {
  Id:string,
 Label:string
}

export interface OptionType {
  label: string;
  value: string;
}
export interface OptionNumberType {
  label: string;
  value: number;
}

export interface SelectProps extends OptionProps, WidthProps {
  props: ControlProps;
  childrenToggleSee: ReactNode;
}

export const CreateDefaultStyle = <T,>({
  privateType,
  isPrivate,
}: {
  privateType?: boolean;
  isPrivate?: boolean;
}) => {
  return {
    placeholder: (base, state) => ({
      ...base,
      padding: "0px",
      margin: "0px",
      textAlign: "start",
      color: theme().colors.thirdTextContrast,
      fontFamily: "BauPro",
      fontSize: theme().fontSizes[3],
      textTransform: "uppercase",
      position: "absolute",
      left: -5,
      display: "none",
    }),

    indicatorSeparator: () => ({
      display: "none",
    }),

    dropdownIndicator: (base, state) => ({
      ...base,
      padding: 0,
      color: theme().colors.primaryBg,
      fontSize: "17px",
      transform: state.selectProps.menuIsOpen ? "rotate(-180deg)" : null,
      cursor: "pointer",
      transition: "transform 0.1s ease",
      "&:hover": {
        color: theme().colors.primaryBg,
      },
    }),

    container: (provided, state) => ({
      ...provided,
      minWidth: "300px",
      backgroundColor: "transparent",
      alignItems: "center",
      display: "flex",
      border: "none",
      userSelect: "none",
      opacity: state.isDisabled ? "0.5" : "1",
      "&:hover": {
        border: "none",
      },
      "&:focus-within": {
        outline: "none",
      },
      "&:focus-visible": {
        outline: "none",
      },
    }),
    control: (baseStyles, state) => ({
      ...baseStyles,
      display: "grid",
      gridTemplateColumns: "1fr auto auto",
      minHeight: "36px",
      boxShadow: "none",
      border: "none",
      width: "100%",
      fontSize: "12px",
      backgroundColor: theme().colors.grayBase,
      borderRadius: state.menuIsOpen ? "20px 20px 0 0" : "20px",
      padding: isPrivate ? "0px  45px  0px 14px !important" : "8px 16px",

      "&:focus-within": {
        outline: "none",
      },
      "&:hover": {
        outline: "none",
      },
      "&:focus-visible": {
        outline: "none",
      },
    }),

    menu: (baseStyles, state) => ({
      ...baseStyles,
      width: "100%",
      position: "absolute",
      margin: "0",
      padding: "0",
      borderColor: theme().colors.thirdTextContrast,
      borderRadius: " 0 0 18px 18px",
      borderBottom: `18px solid ${theme().colors.grayBg}`,
      overflow: "hidden",
      zIndex: "20",
      opacity: 1,
      boxShadow: "0 2px 4px #25242b",
      backgroundColor: theme().colors.graySolid,
      "&:focus": {
        backgroundColor: theme().colors.graySolid,
      },
    }),

    menuList: (provided, state) => ({
      ...provided,
      opacity: 1,
      width: "100%",
      border: "none",
      maxHeight: "166px",
      margin: "0",
      padding: "0",
      overflowY: "scroll",
      borderBottom: `0.2px solid ${theme().colors.thirdTextContrast}`,
      backgroundColor: state.isMulti ? theme().colors.graySolid : theme().colors.graySolid,
    }),
    input: (provided, state) => ({
      ...provided,
      color: theme().colors.thirdTextContrast,
      fontFamily: "BauPro",
      textTransform: "inherit",
      fontSize: theme().fontSizes[3],
      opacity: "1 !important",
    }),

    valueContainer: (provided, state) => ({
      ...provided,
      display: "flex",
      alignItems: "center",
      padding: "0px",
      margin: "0px",
      overflow: "visible",
      opacity: "1 !important",
    }),

    option: (provided, state) => {
      const optionStyle = {
        ...provided,
        display: "flex",
        alignItems: "center",
        textAlign: "left",
        fontFamily: "BauPro",
        textTransform: "inherit",
        fontSize: theme().fontSizes[3],
        height: "36px",
        cursor: "pointer",
        borderTop: "1px solid",
        borderColor: theme().colors.thirdBase,
        backgroundColor: state.isSelected ? theme().colors.grayBg : theme().colors.grayBase,
        color: theme().colors.thirdBase,
        opacity: "1 !important",
        "&:hover": {
          backgroundColor: theme().colors.primaryBase,
          color: theme().colors.secondaryBase,
          opacity: "1 !important",
        },
        "&:active": {
          backgroundColor: theme().colors.primaryBase,
          opacity: "1 !important",
        },
        "&:focus": {
          backgroundColor: theme().colors.primaryBase,
          opacity: "1 !important",
        },
      };
      return optionStyle;
    },
    singleValue: (provided, state) => {
      const singleStyle = {
        ...provided,
        textAlign: "left",
        opacity: state.isDisabled ? 0.5 : 1,
        transition: "opacity 300ms",
        color: privateType ? "transparent" : theme().colors.thirdText,
        fontFamily: "BauPro",
        fontSize: theme().fontSizes[3],
        textTransform: "inherit",
      };
      return singleStyle;
    },
  } as StylesConfig<T>;
};

export const Control = ({ children, ...props }: ControlProps) => {
  return <components.Control {...props}>{children}</components.Control>;
};

/* const Select = ({ option, width }: SelectProps): JSX.Element => {
  return (
    <ReactSelect
      options={option}
      styles={{
        ...style,
        container: (provided: any) => ({ ...provided, width }),
      }}
    />
  );
}; */
