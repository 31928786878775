import { SpaceProps, WidthProps } from "styled-system";
import Flex from "../primitives/Flex";
import SelectField from "components/molecules/SelectField";
import CalendarField from "components/molecules/CalendarField";
import { MobileNavigationBottom } from "components/atoms/MobileNavigationBottom";
import Box from "components/primitives/Box";
import { OptionNumberType } from "components/primitives/Select";
import { useContext } from "react";
import { AppGeneralContext } from "context/AppGeneralContext";
import Button from "components/primitives/Button";
import Txt from "components/primitives/Txt";
import * as Yup from "yup";
import InputFieldWardrobe from "components/molecules/InputFieldWardrobe";
import { useFormik } from "formik";
import Form from "components/primitives/Form";
import { GenderEnum } from "utils";
import { useTranslation } from "react-i18next";
import { i18Enum } from "i18n/types/translationType";

export interface FormStepLoginFirst {
  GivenName: string;
  FamilyName: string;
  BirthDate?: Date;
  Gender?: number;
}

type FormStepFirstSchemaObject = {
  [key in keyof FormStepLoginFirst]: Yup.Schema<any>;
};

const initialFormStepLoginFirst: FormStepLoginFirst = {
  GivenName: "",
  FamilyName: "",
  BirthDate: undefined,
  Gender: undefined,
};

interface StepLoginFirstFormProps extends WidthProps, SpaceProps {
  onSubmit: (value: FormStepLoginFirst) => void;
  isPrivate: boolean | undefined;
  data?: FormStepLoginFirst | null;
}

function StepLoginFirstForm({ onSubmit, isPrivate, data, ...props }: StepLoginFirstFormProps) {
  const { t } = useTranslation();
  const { isMobile, filterList: categoriesTag, genderFilter } = useContext(AppGeneralContext);

  const formStepLoginFirstSchema = Yup.object().shape<FormStepFirstSchemaObject>({
    GivenName: Yup.string().required(t(i18Enum.Validation_Client_Field_Required)),
    FamilyName: Yup.string().required(t(i18Enum.Validation_Client_Field_Required)),
    BirthDate: Yup.date().nullable(),
    Gender: Yup.number().required(t(i18Enum.Validation_Client_Field_Required)),
  });
  
  const formik = useFormik<FormStepLoginFirst>({
    initialValues: data ? data : initialFormStepLoginFirst,
    validationSchema: formStepLoginFirstSchema,
    enableReinitialize: true,
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true);

      onSubmit(values);

      setSubmitting(false);
    },
  });

  return (
    <>
      <Form padding={" 5%"} width={[1]} flexDirection={"column"} alignItems={"center"} justifyContent={"center"} {...props} onSubmit={formik.handleSubmit}>
        <Flex width={[1, 1, "500px"]} padding={["0 5%", "0 0"]} flexDirection={"column"} maxWidth={"500px"} marginBottom={50}>
          <InputFieldWardrobe
            {...formik.getFieldProps("GivenName")}
            name={"GivenName"}
            id={"GivenName"}
            autoComplete={"given-name"}
            placeholder={t(i18Enum.User_UserProfile_Label_GivenName)}
            label={"GivenName"}
            spaceProp={{ width: [1], marginTop: [4], padding: ["10px 0"] }}
            onChange={formik.handleChange}
            onBlur={() => formik.setFieldTouched("GivenName", true)}
            value={formik.values.GivenName}
            error={formik.errors.GivenName ? formik.errors.GivenName : undefined}
          />
          <InputFieldWardrobe
            {...formik.getFieldProps("FamilyName")}
            name={"FamilyName"}
            id={"FamilyName"}
            autoComplete={"family-name"}
            placeholder={t(i18Enum.User_UserProfile_Label_FamilyName)}
            label={"FamilyName"}
            spaceProp={{ width: [1], marginTop: [3], padding: ["10px 0"] }}
            onChange={formik.handleChange}
            onBlur={() => formik.setFieldTouched("FamilyName", true)}
            value={formik.values.FamilyName}
            error={formik.errors.FamilyName ? formik.errors.FamilyName : undefined}
          />
          <CalendarField
            id='BirthDate'
            paddingTop={2}
            paddingBottom={2}
            onBlur={() => formik.setFieldTouched("BirthDate", true)}
            placeholder={t(i18Enum.User_UserProfile_Label_BirthDate)}
            isPrivate={isPrivate}
            width={[1]}
            // value={formik.values?.date ? new Date(formik.values.date) : today}
            value={formik.values.BirthDate ? new Date(formik.values.BirthDate) : null}
            onChange={(date?: Date) => formik.setFieldValue("BirthDate", date?.toISOString())}
          />
          {formik.errors.BirthDate && (
            <Txt padding={"13px 0 0 18px"} variant='error' fontSize={[3]} textAlign={"left"}>
              {String(formik.errors.BirthDate)}
            </Txt>
          )}
          <SelectField<OptionNumberType>
            zIndex={[0, 0, 1]}
            width={[1]}
            marginTop={"1.2em"}
            id='Gender'
            isPrivate={isPrivate}
            placeholder={t(i18Enum.User_UserProfile_Label_Gender)}
            option={genderFilter}
            value={genderFilter.find((x) => Number(x.value) === formik.values.Gender)}
            handleChange={(opt) => formik.setFieldValue("Gender", opt.value)}
            handleBlur={() => {
              formik.setFieldTouched("Gender", true);
            }}
            labelExtractor={(x) => x.label}
            valueExtractor={(x) => GenderEnum[x.value]}
          />
          {formik.errors.Gender && (
            <Txt padding={"13px 0 0 18px"} variant='error' fontSize={[3]} textAlign={"left"}>
              {formik.errors.Gender}
            </Txt>
          )}
        </Flex>
        {!isMobile && (
          <Flex width={[1]} justifyContent={"space-between"} alignItems={"center"} maxWidth={"500px"}>
            <Txt variant='light' fontSize={[2]}>
              {t(i18Enum.User_UserProfile_Section_Personal)}
            </Txt>
            <Button type='submit' variant='primary' padding={"10px 20px"} disabled={!formik.isValid}>
              {t(i18Enum.Common_Next)}
            </Button>
          </Flex>
        )}
        <Box marginBottom={5} />
      </Form>
      {isMobile && <MobileNavigationBottom text={t(i18Enum.Common_Next)} description={t(i18Enum.User_UserProfile_Section_Personal)} disabled={!formik.isValid} buttonAction={formik.handleSubmit} />}
    </>
  );
}

export default StepLoginFirstForm;
