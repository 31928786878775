import i18next, { InitOptions, ResourceKey } from "i18next";
import { initReactI18next } from "react-i18next";

import HttBackand, { HttpBackendOptions } from "i18next-http-backend";
// import { getTranslations } from "../api/getTranslation";
import resources from "./types/resources";

// type RequestCallback = (error: any, response: RequestResponse) => void;
// interface RequestResponse {
//   status: number;
//   data: ResourceKey;
// }

// const backendTranslationsRequest = (
//   options: HttpBackendOptions,
//   url: string,
//   payload: {},
//   callback: RequestCallback
// ) => {
//   getTranslations(url).then((response) => {
//     callback(null, {
//       data: response,
//       status: 200,
//     });
//   });
// };

// const backandOptions: HttpBackendOptions = {
//   request: backendTranslationsRequest,
//   crossDomain: true,
//   loadPath: "/Language/{{lng}}",
// };

export const initI18nextOption: InitOptions = {
  debug: true,
  fallbackLng: "IT",
  load: "all",
  supportedLngs: ["EN", "IT"],
  lng: "IT", //default
  // backend: backandOptions,
  resources: resources,
  interpolation: {
    escapeValue: false,
  },
  react: {
    useSuspense: true,
  },
};

i18next
  .use(HttBackand)
  .use(initReactI18next)
  .init<HttpBackendOptions>(initI18nextOption);
