import Txt from "../primitives/Txt";
import SelectSize from "../molecules/SelectSize";
import Flex from "../primitives/Flex";
import Button from "../primitives/Button";
import CartSvg from "../atoms/CartSvg";
import Accordion from "../molecules/Accordion";
import GridCard from "./GridCard";
import { capitalize, useScrollBlock } from "../../utils";
import { useContext } from "react";
import { CustomThemeContext } from "../../context/ThemeContext";
import ShopDetailProduct from "../molecules/ShopDetailProduct";
import { ProductType, VariantDTO } from "redux/features/product/ProductsTypes";
import { SelectedProduct } from "utils/generalTypes/Frontend";
import { i18Enum } from "i18n/types/translationType";
import { useTranslation } from "react-i18next";
import LoadingSpinner from "components/molecules/LoadingSpinner";
import { RenderHTML } from "components/primitives/RenderHTML";

interface OpenProductDetailsProps {
  product?: ProductType | null;
  selected?: VariantDTO | null;
  handleSelection: (product?: VariantDTO) => void;
  productVariants: VariantDTO[];
  similarProduct?: ProductType[];
  productToBasket: (product: SelectedProduct) => void;
  addToCart: (isBuyNow: boolean) => void;
  isBuyNow?: boolean;
  cartIsUpdating?: boolean;
}

function OpenProductDetail({ product, selected, productVariants, similarProduct, productToBasket, addToCart, cartIsUpdating, isBuyNow }: OpenProductDetailsProps) {
  const { customTheme } = useContext(CustomThemeContext);
  const colors = customTheme.colors;
  const { t } = useTranslation();
  const [allowScroll] = useScrollBlock();
  return (
    <>
      <Flex flexDirection={"column"} padding={"0 23px 0px"}>
        <Txt marginTop={"29px"} variant='light' color={colors.thirdBase} fontSize={[2]} style={{ textTransform: "inherit" }}>
          {capitalize(product?.ShortDescription)}
        </Txt>

        <SelectSize
          variant='xl'
          marginTop={"28px"}
          title={t(i18Enum.Product_SelectSize)}
          selected={selected}
          productVariants={productVariants ?? []}
          productToBasket={(product) => productToBasket(product)}
        />

        <Flex width={"100%"} flexDirection={"column"} border={`solid ${colors.thirdBase}`} borderWidth={"1px 0 1px 0"} margin={"16px 0 20px 0"}>
          <Button
            width={"100%"}
            padding={"13px 0"}
            backgroundColor={"transparent"}
            onClick={() => {
              allowScroll();
              addToCart(true);
            }}
          >
            <CartSvg width={"1.1428em"} height={"1.4285em"} />
            <Txt variant='medium' color={colors.thirdBase} fontSize={[3]} marginLeft={"10px"} paddingTop={"3px"}>
              {cartIsUpdating && isBuyNow ? <LoadingSpinner dimension='30px' /> : t(i18Enum.Product_Button_BuyNow)}
            </Txt>
          </Button>
        </Flex>

        <Accordion variant={"card"} title={t(i18Enum.Product_Label_Details)}>
          <Txt as='p' variant='light' color={colors.thirdBase} fontSize={[2]} margin='0' style={{ textTransform: "inherit" }}>
            {capitalize(product?.Description)}
          </Txt>
        </Accordion>
        <Accordion variant={"card"} title={t(i18Enum.Product_Label_MaterialAndCare)}>
          <Txt as='p' variant='light' color={colors.thirdBase} fontSize={[2]} style={{ textTransform: "inherit" }} margin='0'>
            {capitalize(product?.Composition)}
          </Txt>
        </Accordion>
        <Accordion variant={"card"} title={t(i18Enum.Product_Label_ShippingInfo)} defaultOpen>
          <RenderHTML variant='unordered' html={t(i18Enum.Product_ShippingInfo)} fontSize={"12px"} />
        </Accordion>
      </Flex>
      <Flex width={[1]} flexDirection={"column"} alignItems={"center"} mt={[20, 0]}>
        <ShopDetailProduct
          title={product?.Shop.Name}
          subtitle={product?.Shop.ShopLocation}
          description={product?.Shop.Description}
          img={product?.Shop.Picture}
          id={product?.ShopId}
          textButton={t(i18Enum.Product_Label_ShowMore)}
        />
      </Flex>

      <Txt paddingLeft={[3]} variant='light' fontSize={[1]} margin={"20px 0"} color={colors.thirdBase} width={[1]} textAlign={"left"}>
        {t(i18Enum.Product_YouMayAlsoLike)}
      </Txt>
      <GridCard isHomeCard={false} products={similarProduct} />
    </>
  );
}

export default OpenProductDetail;
