import { useContext, useEffect, useMemo, useState } from "react";
import { CustomThemeContext } from "context/ThemeContext";
import Flex from "components/primitives/Flex";
import Txt from "components/primitives/Txt";
import MenuNavigateProps from "components/organisms/MenuNavigate";
import AccountInfoForm, { FormAccount } from "components/organisms/AccountInfoForm";
import { capitalize, toastType } from "utils";
import Toggle from "components/primitives/Toggle";
import ProfileInformationForm, { ProfileInfo } from "components/organisms/ProfileInformationForm";
// import NotificationsForm, { FormNotification } from "components/organisms/NotificationsForm";
import Button from "components/primitives/Button";
import Box from "components/primitives/Box";
import { useAppSelector } from "redux/app/hooks";
import { MobileNavigationBottom } from "components/atoms/MobileNavigationBottom";
import { useNavigate } from "react-router-dom";
import { PAGE } from "utils/const/pagePath";
import { UserType } from "redux/features/user/UserTypes";
import { useDispatch } from "react-redux";
import { removeAuthAction } from "redux/features/auth/authSlice";
import { AppGeneralContext } from "context/AppGeneralContext";
import { UserActions } from "redux/features/user/userSlice";
import { useTranslation } from "react-i18next";
import { i18Enum } from "i18n/types/translationType";

export function AccountPage() {
  const { customTheme } = useContext(CustomThemeContext);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { addToast, setIsLoading, genderFilter } = useContext(AppGeneralContext);
  const colors = customTheme.colors;
  const { isLoading: isFectchingUser, data: userData } = useAppSelector((state) => state.user.user);
  const [req, setReq] = useState<UserType | undefined>(userData ?? undefined);
  const [showDelete, setShowDelete] = useState<boolean>(false);
  const [showEditButton, setShowEditButton] = useState<boolean>(false);
  const [isProfileValid, setIsProfileValid] = useState<boolean>(false);
  const updateReq = (updates: Partial<UserType>) => {
    const updatedReq = { ...req, ...updates } as UserType;
    setReq(updatedReq);
  };
  const profileInfo: ProfileInfo = useMemo(() => {
    return {
      GivenName: userData?.GivenName ?? "",
      FamilyName: userData?.FamilyName ?? "",
      Username: userData?.Username ?? "",
      ShortDescription: userData?.ShortDescription ?? "",
      FacebookUrl: userData?.FacebookUrl ?? "",
      InstagramUrl: userData?.InstagramUrl ?? "",
      TikTokUrl: userData?.TikTokUrl ?? "",
      TwitterUrl: userData?.TwitterUrl ?? "",
      YouTubeUrl: userData?.YouTubeUrl ?? "",
      PinterestUrl: userData?.PinterestUrl ?? "",
    };
  }, [userData]);
  const [isPrivate, setIsPrivate] = useState<boolean>(userData?.IsPrivate ?? false);
  const maxW = customTheme.maxWidth;
  const dispatch = useDispatch();

  if (userData === undefined) {
    dispatch(removeAuthAction());
  }

  function getEditAccountInfo(values: FormAccount) {
    updateReq(values);
  }

  function getProfileInfo(values: ProfileInfo) {
    updateReq(values);
  }

  // function getNotificationStatus(values: FormNotification) {
  //   updateReq(values);
  // }

  function SendEdit(req: UserType | undefined) {
    if (!req?.Username || req.Username === "") {
      addToast(toastType.warning, t(i18Enum.Error_MissingUsername));
      return;
    }
    dispatch(UserActions.editUserAction(req));
    setShowEditButton(false);
  }

  useEffect(() => {
    setIsLoading(isFectchingUser);
  }, [isFectchingUser]);

  const changeYourPassword = () => {
   navigate("/forgot-password", { state: userData?.Email ?? "" });
  };

  const handleInputChange = () => {
    setShowEditButton(true);
  };

  const questionnaire = () => {
    navigate(PAGE.firstLogin.menuPath);
  };

  const handleShowDelete = () => {
    setShowDelete(true);
  };
  const cancelDelete = () => {
    setShowDelete(false);
  };

  const handleDelete = () => {
    dispatch(UserActions.deleteUserAction());
    navigate(PAGE.home.menuPath);
  };

  const handleIsPrivate = (checked: boolean) => {
    setIsPrivate(checked);
    updateReq({ IsPrivate: checked });
    handleInputChange();
  };

  const paragraph = t(i18Enum.User_UserProfile_Desc_Privacy);
  const legendIsPrivate = t(i18Enum.User_UserProfile_Desc_PrivacyLegend);

  return (
    <Flex position={"relative"} padding={["5% 1em", "5% 1em", "0"]} width={[1]} alignItems={"center"} justifyContent={"center"} flexDirection={"column"}>
      {userData?.Id && (
        <Flex alignItems={"center"} justifyContent={"center"} flexDirection={"column"} maxWidth={maxW[1]} width={[1]}>
          <Box width={1} maxWidth={["none", 1100]}>
            <MenuNavigateProps userData={userData} />
          </Box>
          <Flex key={userData.Id} flexDirection={"column"} width={[1]} padding={["0 1em", "0 1em", 0]} $gap={1} maxWidth={["none", 500]}>
            <Txt as={"h3"} marginTop={"3em"} variant='light' fontSize={[3]} color={colors.thirdText} textAlign={"left"} marginBottom={-3}>
              {t(i18Enum.User_UserProfile_MyAccount)}
            </Txt>

            <AccountInfoForm
              data={{
                BirthDate: userData.BirthDate ? new Date(userData.BirthDate) : undefined,
                Gender: userData.Gender ?? undefined,
              }}
              optionGenders={genderFilter}
              onSubmit={getEditAccountInfo}
              onFieldChange={handleInputChange}
            />

            <Txt as={"a"} marginTop={"1em"} variant='linkLight' fontSize={[3]} color={colors.primaryText} textAlign={"left"} marginBottom={0} onClick={changeYourPassword}>
              {t(i18Enum.User_UserProfile_ChangePassword)}
            </Txt>

            <Txt as={"h3"} marginTop={"1em"} variant='light' fontSize={[3]} color={colors.thirdText} textAlign={"left"} marginBottom={0}>
              {t(i18Enum.User_UserProfile_Section_Profile)}
            </Txt>

            <Txt as={"p"} marginTop={-2} variant='light' fontSize={[3, 4]} $textTransform='inherit' color={colors.thirdText} textAlign={"left"} marginBottom={0}>
              {capitalize(paragraph)}
            </Txt>

            <Flex alignItems={"center"} width={[1]}>
              <Toggle checked={isPrivate} handleChange={handleIsPrivate} />
              <Txt fontSize={[3]} lineHeight={"12px"} marginLeft={[3]} variant='light'>
                {isPrivate ? t(i18Enum.Common_AccountPublic) : t(i18Enum.Common_AccountPrivate)}
              </Txt>
            </Flex>

            <Txt
              width={[1]}
              variant='light'
              $textTransform='inherit'
              textAlign={"left"}
              opacity={!isPrivate ? 0 : 1}
              maxHeight={!isPrivate ? 10 : 100}
              fontSize={[2, 3, 4]}
              style={{ transition: "all 0.5s ease-out" }}
            >
              {capitalize(legendIsPrivate)}
            </Txt>

            <ProfileInformationForm
              onSubmit={getProfileInfo}
              onFieldChange={handleInputChange}
              isFormValid={(value) => setIsProfileValid(value)}
              data={profileInfo}
              isPrivate={isPrivate ? true : undefined}
            />

            {/* <Txt as={"h3"} marginTop={0} variant='light' fontSize={[3]} color={colors.thirdText} textAlign={"left"} marginBottom={0}>
              {t(i18Enum.User_NotificationSettings_Title)}
            </Txt>

            <NotificationsForm
              onSubmit={getNotificationStatus}
              data={{
                NotificationWishlistOnStockEnabled: userData.NotificationWishlistOnStockEnabled ?? false,
                NotificationOrderShippedEnabled: userData.NotificationOrderShippedEnabled ?? false,
                NotificationFollowingBirthdayEnabled: userData.NotificationFollowingBirthdayEnabled ?? false,
              }}
            /> */}

            <Txt as={"h3"} marginTop={0} variant='light' fontSize={[3]} color={colors.thirdText} textAlign={"left"} marginBottom={0}>
              {t(i18Enum.User_UserProfile_Section_Preferences)}
            </Txt>

            <Txt as={"a"} marginTop={"0"} variant='linkLight' fontSize={[3]} color={colors.primaryText} textAlign={"left"} marginBottom={0} onClick={questionnaire}>
              {t(i18Enum.User_UserProfile_Repeat_Quiz)}
            </Txt>
            {showDelete ? (
              <Box backgroundColor={"#FF3300"} width={"100%"} marginBottom={"100px"} borderRadius={"16px"} padding={"16px"}>
                <Txt as='h3' textAlign={["center"]} color='black' marginTop={"5px"}>
                  ⚠️
                </Txt>
                <Txt as='h5' textAlign={["center"]} color='black'>
                  {t(i18Enum.User_Deactivate_Title_AreYouSure)}
                </Txt>
                <Flex flexDirection={["column", "row"]} justifyContent={"center"} alignItems={["center", "end"]} padding={"20px 0 10px 0"}>
                  <Button variant='secondary' width={"fit-content"} padding={"0.5em 1.2em"} onClick={cancelDelete} marginRight={["0px", "10px"]} marginBottom={["10px", "0px"]}>
                    {t(i18Enum.User_Deactivate_Skip)}
                  </Button>
                  <Button variant='warning' width={"fit-content"} padding={"0.5em 1.2em"} onClick={handleDelete}>
                    {t(i18Enum.User_Deactivate_DeactivateMyAccount)}
                  </Button>
                </Flex>
              </Box>
            ) : (
              <Button variant='danger' width={"fit-content"} padding={"0.5em 1.2em"} margin={"20px 0 100px"} onClick={handleShowDelete}>
                {t(i18Enum.User_Deactivate_DeactivateMyAccount)}
              </Button>
            )}
          </Flex>

          {(showEditButton || !req?.Username) && <MobileNavigationBottom text={t(i18Enum.Common_Save)} disabled={!isProfileValid} description={t(i18Enum.Common_EditAccount)} buttonAction={() => SendEdit(req)} />}
        </Flex>
      )}
    </Flex>
  );
}
