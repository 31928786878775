import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import Flex from "../primitives/Flex";
import { CustomThemeContext } from "../../context/ThemeContext";
import amex from "../../assets/img/payment-amex.svg";
import master from "../../assets/img/payment-mastercard.svg";
import pay from "../../assets/img/payment-pay.svg";
import visa from "../../assets/img/payment-visa.svg";
import paypal from "../../assets/img/payment-paypal.svg";
import Txt from "../primitives/Txt";
import icon from "../../assets/icons/logo.svg";
import Accordion from "../molecules/Accordion";
import Image from "../primitives/Image";
import socialFacebook from "../../assets/icons/socialFacebook.svg";
import socialInstagram from "../../assets/icons/socialInstagram.svg";
import socialTiktok from "../../assets/icons/socialTiktok.svg";
import { AppGeneralContext } from "../../context/AppGeneralContext";
import { PAGE } from "utils/const/pagePath";
import { socialLink } from "utils/links/socialLink";
import { i18Enum } from "i18n/types/translationType";
import { useTranslation } from "react-i18next";

interface FooterProps {
  // Definir las propiedades del Footer aquí, si las hay.
}

const Footer: React.FC<FooterProps> = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const payment = [master, visa, amex, pay, paypal];
  const accordionSocial = [
    {
      icon: socialFacebook,
      navigate: socialLink.facebook,
    },
    {
      icon: socialTiktok,
      navigate: socialLink.tiktok,
    },
    {
      icon: socialInstagram,
      navigate: socialLink.instagram,
    },
  ];
  const accordionData = [
    {
      title: t(i18Enum.Footer_Explore),
      options: [
        // { label: t(i18Enum.Footer_Shopping), isExternal: false, navigate: PAGE.shopList.menuPath },
        { label: t(i18Enum.Footer_Community), isExternal: false, navigate: PAGE.community.menuPath },
        { label: t(i18Enum.Footer_Magazine), isExternal: false, navigate: PAGE.magazine.menuPath },
      ],
    },
    {
      title: t(i18Enum.Footer_Information),
      options: [
        { label: t(i18Enum.Footer_PaymentOptions), isExternal: false, navigate: PAGE.payment.menuPath },
        { label: t(i18Enum.Footer_Shipping), isExternal: false, navigate: PAGE.shippings.menuPath },
        { label: t(i18Enum.Footer_DeliveryAndReturns), isExternal: false, navigate: PAGE.returnedGoods.menuPath },
        {
          label: t(i18Enum.Footer_TermsAndConditions),
          isExternal: false,
          navigate: PAGE.termsAndConditions.menuPath,
        },
      ],
    },
    {
      title: "mooore",
      options: [
        { label: t(i18Enum.Footer_AboutUs), isExternal: false, navigate: PAGE.aboutUs.menuPath },
        { label: t(i18Enum.Footer_Contact), isExternal: true, navigate: "mailto:info@mooore.com?subject=richiesta%20informazioni" },
        { label: t(i18Enum.Footer_AffiliateProgram), isExternal: false, navigate: PAGE.affiliateProgram.menuPath },
        { label: t(i18Enum.Footer_WorkWithUs), isExternal: true, navigate: "mailto:info@mooore.com?subject=lavora%20con%20MOOORE" },
      ],
    },
  ];

  const { customTheme } = useContext(CustomThemeContext);
  const colors = customTheme.colors;
  const { isMobile, setResetShopFilter } = useContext(AppGeneralContext);
  const handleNavigation = (link: string, external: boolean): void => {
    if(link === PAGE.shopList.menuPath){
      setResetShopFilter(true)
    }
    if (external) window.location.href = link;
    else navigate(link);
  };

  return (
    <Flex flexDirection={"column"} maxWidth={[customTheme.maxWidth[2]]} width={[1]} padding={["2em"]}>
      <Flex justifyContent={["start", "center"]} flexWrap={"wrap"} $gap={2}>
        {payment.map((uri, index) => (
          <Image key={index} src={uri} alt='payment' maxWidth={["90px"]} width={["25%", "10%"]} height={"auto"} />
        ))}
      </Flex>
      <Flex flexDirection={["column", "row"]} alignItems={["start", "unset"]} justifyContent={["unset", "space-between"]} marginTop={[5]} marginBottom={[5]}>
        <Flex flexDirection={"column"}>
          {!isMobile && (
            <>
              <Image src={icon} alt='payment' width='100%' />
              <Txt marginBottom={2} mt={2} variant='light' color={colors.thirdBase} fontSize={[2]}>
                ME + MYSELF + MY LIFESTYLE HUB
              </Txt>
            </>
          )}
          <Accordion defaultOpen={true} variant='footer' title={t(i18Enum.Footer_FollowUs)} fontSize={[2]}>
            <Flex $gap={2}>
              {accordionSocial.map((social, index) => (
                <Image key={social.icon + index} style={{ cursor: "pointer" }} src={social.icon} alt={"social icon"} width={["36px", "25px"]} onClick={() => handleNavigation(social.navigate, true)} />
              ))}
            </Flex>
          </Accordion>
        </Flex>
        <Flex flexDirection={["column", "row"]} width={["unset", "100%"]} justifyContent={["space-around"]}>
          {accordionData.map((item, index) => (
            <Accordion key={index} variant='footer' title={item.title} fontSize={[2]} defaultOpen={isMobile ? false : true}>
              <Flex flexDirection='column' $gap={0.2}>
                {item.options.map((option) => (
                  <Txt
                    as={"a"}
                    paddingTop={2}
                    fontSize={[2]}
                    variant='linkLightSimple'
                    textAlign='left'
                    key={option.label}
                    color={colors.thirdBase}
                    onClick={() => handleNavigation(option.navigate, option.isExternal)}
                  >
                    {option.label}
                  </Txt>
                ))}
              </Flex>
            </Accordion>
          ))}
        </Flex>
      </Flex>

      <Flex flexDirection='column' $gap={0.6} alignItems='center'>
        {isMobile && (
          <>
            <Image src={icon} alt='payment' width='min-content' />

            <Txt variant='light' color={colors.thirdBase} fontSize={[3]}>
              ME + MYSELF + MY LIFESTYLE HUB
            </Txt>
          </>
        )}
        <Txt variant='light' fontSize={[0, 0]} letterSpacing={[0, 1]} color={colors.thirdBase}>
          {t(i18Enum.Footer_Copyright)}
          {` - `}
          <Txt $hoverColor={colors.primaryBase} fontFamily={"BauPro"} variant='link' onClick={() => navigate(PAGE.termsAndConditions.menuPath)}>
            {t(i18Enum.Footer_TermsAndConditions)}
          </Txt>
          {` - `}
          <Txt $hoverColor={colors.primaryBase} fontFamily={"BauPro"} fontWeight={"400"} variant='link' onClick={() => navigate(PAGE.privacyPolicy.menuPath)}>
            {t(i18Enum.Footer_PrivacyPolicy)}
          </Txt>
          {` & `}
          <Txt $hoverColor={colors.primaryBase} fontFamily={"BauPro"} fontWeight={"400"} variant='link' onClick={() => navigate(PAGE.cookiePolicy.menuPath)}>
            {t(i18Enum.Footer_CookiePolicy)}
          </Txt>
          {` - `}
          V: 0.0.21
        </Txt>
      </Flex>
    </Flex>
  );
};

export default Footer;
