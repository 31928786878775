import { useContext, useEffect, useRef } from "react";
import { useTheme } from "styled-components";
import { AppGeneralContext } from "context/AppGeneralContext";
import { CustomThemeContext } from "context/ThemeContext";
import { useAppDispatch, useAppSelector } from "redux/app/hooks";
import Section from "components/primitives/Section";
import Flex from "components/primitives/Flex";
import Txt from "components/primitives/Txt";
import { H3 } from "theme/theme.styled";
import Lottie from "lottie-light-react";
import loader from "assets/lotties/loader.json";
import { ErrorComponent } from "components/molecules";
import { BrandActions } from "redux/features/brand/brandSlice";
import Button from "components/primitives/Button";
import { useNavigate } from "react-router-dom";
import { PAGE } from "utils/const/pagePath";
import useIntersection from "utils/hooks/useIntersection";
import { useTranslation } from "react-i18next";
import { i18Enum } from "i18n/types/translationType";

export const BrandsList = () => {
  const { isMobile } = useContext(AppGeneralContext);
  const { customTheme } = useContext(CustomThemeContext);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const bottom = useRef<HTMLDivElement | null>(null);
  const inViewport = useIntersection(bottom, "5px");
  const theme = useTheme();
  const maxW = customTheme.maxWidth;
  const { isLoading: isFetchingList, errors } = useAppSelector((state) => state.brand.brandsList);
  const brandsList = useAppSelector((state) => state.brand.brandsList.data?.Data);
  const pager = useAppSelector((state) => state.brand.brandsList.data?.Pager);

  useEffect(() => {
    if (!!pager?.TotalPages && pager?.PageNum >= pager?.TotalPages) {
      return;
    }
    dispatch(BrandActions.getBrandsListAction({ PageNum: (pager?.PageNum ?? 0) + 1, PageSize: 10 }));
    console.log("aaa", pager?.PageNum);
  }, []);

  useEffect(() => {
    if (inViewport?.isVisible && !isFetchingList) {
      if (!pager?.TotalPages || pager?.PageNum >= pager?.TotalPages) {
        return;
      }
      const newPage = pager?.PageNum + 1;
      dispatch(BrandActions.getBrandsListAction({ PageNum: newPage, PageSize: 10 }));
    }
  }, [inViewport.isVisible]);

  const reloadBrandsPage = () => {
    dispatch(BrandActions.getBrandsListAction({ PageNum: 1, PageSize: 10 }));
  };

  return (
    <Flex width={[1]} flexDirection={"column"} alignItems={"center"}>
      <Flex marginTop={isMobile ? 100 : 3} width={[1]} maxWidth={theme.maxWidth[0]} flexDirection={["column"]} alignItems={["center", "left"]} padding={"0 1.5em"} marginBottom={"10em"}>
        <Section minHeight={"150px"} padding={"0 5%"} width={[1]} flexDirection={"column"} justifyContent={"center"}>
          <Flex maxWidth={[maxW[0]]} width={[1]} flexDirection={"column"} justifyContent={"center"} alignItems={"center"}>
            <Txt as={"h5"} width={[1]} fontFamily={"BauPro"} fontWeight={"500"} fontSize={H3}>
              {t(i18Enum.Brands_Title)}
            </Txt>
          </Flex>
        </Section>

        {/* <Search width={[1, "500px"]} placeholder='aasss' short={true} isfilter={false} isFocused={true} control={""} value={""} isOpen={false} />
      <Box width={"min-content"} padding={"30px 0"}>
        <Button variant={"success"} fontSize={[3]} fontWeight={500} width={["236px"]} height={["43px"]} onClick={() => handleSearch(watch().search)}>
          aaaaa
        </Button>
      </Box> */}
        {errors !== undefined && errors !== "" ? (
          <ErrorComponent errors={errors} onClickHandler={reloadBrandsPage} />
        ) : (
          <Flex as={"ul"} width={[1]} $gap={3} flexDirection={["column"]} marginTop={"2em"}>
            {!brandsList?.length && !isFetchingList && "error"}
            {brandsList?.map((brand) => (
              <Flex as='li' key={brand.Id} width={[1]} alignItems={"center"} justifyContent={"space-between"}>
                {/* <Image src={props.brand.Picture} width={54} $aspectRatio='1' borderRadius={"50%"} style={{ cursor: "pointer" }} /> */}
                <Flex
                  style={{ wordWrap: "break-word" }}
                  flexDirection={"column"}
                  width={["60%", "70%", "80%"]}
                  alignItems={"start"}
                  $gap={1}
                  onClick={() => navigate(`${PAGE.brand.menuPath}/${brand.Code}`, { state: { id: brand.Id } })}
                >
                  <Txt variant='medium' textAlign={"start"} fontSize={[2]} $cursor='pointer'>
                    {`${brand.Name}`}
                  </Txt>
                  {!isMobile && (
                    <Txt variant='medium' textAlign={"justify"} fontSize={[2]} color={theme.colors.primaryText} $textTransform='inherit' $cursor='pointer'>
                      {brand.Description}
                    </Txt>
                  )}
                </Flex>
                <Button width={["30%", "20%", "10%"]} padding={"10px"} variant='primary' onClick={() => navigate(`${PAGE.brand.menuPath}/${brand.Code}`, { state: { id: brand.Id } })}>
                  {t(i18Enum.Brands_Button)}
                </Button>
              </Flex>
            ))}
          </Flex>
        )}
        <Section ref={bottom} width={["50%", "20%"]} maxWidth={[null, theme.maxWidth[0]]} padding={["5% 0"]} flexDirection={"column"} alignItems={"center"} justifyContent={"start"}>
          {isFetchingList && <Lottie animationData={loader} autoPlay />}
        </Section>
      </Flex>
    </Flex>
  );
};
