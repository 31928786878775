/* eslint-disable react-hooks/exhaustive-deps */
import { Dispatch, FC, PropsWithChildren, ReactNode, SetStateAction, createContext, useEffect, useState } from "react";
import { useMediaQuery } from "@uidotdev/usehooks";

import { toast } from "react-toastify";
import { toastType } from "../utils/generalTypes/enum";
import { Pager } from "../api/types/responses";
import { Options, PagerInitialState, toastErrorStyle, toastSuccessStyle, toastWarningStyle, useScrollBlock } from "../utils";
import { SizeCategory } from "utils/generalTypes/category";
import { FilterList, OutfitHomePageDTO } from "api/types/responses/HomePageResponse";
import { ProductType } from "redux/features/product/ProductsTypes";
import { OptionNumberType } from "components/primitives/Select";
import { useTranslation } from "react-i18next";
import { i18Enum } from "i18n/types/translationType";
import { GenderEnum } from "utils";
import { HomepageSliderDTO } from "utils/generalTypes/homepageSlider";
import { ProductsListRequest } from "api/types/requests/productRequest";
import { HomepagePopupDTO } from "utils/generalTypes/popupSlider";

interface AppGeneralContextProps {
  isPotrait: boolean;
  isMobile: boolean;
  //primo accesso al sito per le animazioni navbar
  start: boolean;
  setStart: Dispatch<SetStateAction<boolean>>;
  setIsMobile: (isMobile: boolean) => void;
  addToast: (type: toastType, message: string) => void;
  page: number | undefined;
  setPage: (page: number | undefined) => void;
  pager: Pager;
  setPager: Dispatch<SetStateAction<Pager>>;
  isLoading: boolean;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  asideIsOpen: boolean | null;
  setAsideIsOpen: Dispatch<SetStateAction<boolean | null>>;
  openAsideMenu: <T>(children: ReactNode, params: T) => void;
  closeAndResetAside: () => void;
  asideChildren: ReactNode | undefined;
  setAsideChildren: Dispatch<SetStateAction<ReactNode | undefined>>;
  asideParams: any | undefined;
  setAsideParams: Dispatch<SetStateAction<any | undefined>>;
  outfits: OutfitHomePageDTO[] | undefined;
  setOutfits: Dispatch<SetStateAction<OutfitHomePageDTO[] | undefined>>;
  outfit: OutfitHomePageDTO | undefined;
  setOutfit: Dispatch<SetStateAction<OutfitHomePageDTO | undefined | undefined>>;
  parsedSpicyProduct: ProductType[] | undefined;
  setParsedSpicyProduct: Dispatch<SetStateAction<ProductType[] | undefined>>;
  homepageSliderContent: HomepageSliderDTO[] | undefined;
  setHomepageSliderContent: Dispatch<SetStateAction<HomepageSliderDTO[] | undefined>>;
  homepagePopup: HomepagePopupDTO[] | undefined;
  setHomepagePopup: Dispatch<SetStateAction<HomepagePopupDTO[] | undefined>>;
  sizeCategories: SizeCategory[] | undefined;
  setSizeCategories: Dispatch<SetStateAction<SizeCategory[] | undefined>>;
  filterList: FilterList | undefined;
  setFilterList: Dispatch<SetStateAction<FilterList | undefined>>;
  asideNavigation: string | undefined;
  asideCurrentPath: string | undefined;
  genderFilter: OptionNumberType[];
  cardsMenu: Options[];
  searchKeyword: string;
  setSearchKeyword: Dispatch<SetStateAction<string>>;
  userId: string;
  setUserId: Dispatch<SetStateAction<string>>;
  shopParamsBackup: ProductsListRequest | undefined;
  setShopParamsBackup: Dispatch<SetStateAction<ProductsListRequest | undefined>>;
  resetShopFilter: boolean;
  setResetShopFilter: Dispatch<SetStateAction<boolean>>;
}

const appContextInit: AppGeneralContextProps = {
  isPotrait: true,
  isMobile: false,
  start: false,
  setStart: () => {},
  setIsMobile: () => {},
  addToast: () => {},
  page: undefined,
  setPage: () => {},
  pager: PagerInitialState,
  setPager: () => {},
  isLoading: false,
  setIsLoading: () => {},
  asideIsOpen: false,
  setAsideIsOpen: () => {},
  openAsideMenu: () => {},
  asideChildren: undefined,
  setAsideChildren: () => {},
  asideParams: undefined,
  setAsideParams: () => {},
  asideNavigation: undefined,
  asideCurrentPath: undefined,
  closeAndResetAside: () => {},
  outfits: undefined,
  setOutfits: () => {},
  outfit: undefined,
  setOutfit: () => {},
  parsedSpicyProduct: undefined,
  setParsedSpicyProduct: () => {},
  homepageSliderContent: undefined,
  setHomepageSliderContent: () => {},
  sizeCategories: undefined,
  setSizeCategories: () => {},
  filterList: undefined,
  setFilterList: () => {},
  genderFilter: [{ label: "", value: -1 }],
  cardsMenu: [{ name: "", value: "" }],
  searchKeyword: "",
  setSearchKeyword: () => {},
  userId: "",
  setUserId: () => {},
  shopParamsBackup: undefined,
  setShopParamsBackup: () => {},
  resetShopFilter: false,
  setResetShopFilter: () => {},
  homepagePopup: undefined,
  setHomepagePopup: () => {},
};

export const AppGeneralContext = createContext<AppGeneralContextProps>(appContextInit);

export const AppGeneralContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const { t } = useTranslation();
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const [isPotrait, setIsPotrait] = useState<boolean>(false);
  const [start, setStart] = useState<boolean>(false);
  const [page, setPage] = useState<number | undefined>(undefined);

  const isSmallDevice = useMediaQuery("screen and (max-width : 40em)");
  const isPotraitView = useMediaQuery("screen and (orientation: portrait)");
  
  const [pager, setPager] = useState<Pager>(PagerInitialState);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [asideIsOpen, setAsideIsOpen] = useState<boolean | null>(null);
  const [asideChildren, setAsideChildren] = useState<ReactNode | undefined>();
  const [asideParams, setAsideParams] = useState<any | undefined>();
  const [asideNavigation, setAsideNavigation] = useState<string | undefined>();
  const [asideCurrentPath, setAsideCurrentPath] = useState<string | undefined>();
  const [outfits, setOutfits] = useState<OutfitHomePageDTO[] | undefined>();
  const [outfit, setOutfit] = useState<OutfitHomePageDTO | undefined>();
  const [parsedSpicyProduct, setParsedSpicyProduct] = useState<ProductType[]>();
  const [homepageSliderContent, setHomepageSliderContent] = useState<HomepageSliderDTO[] | undefined>();
  const [sizeCategories, setSizeCategories] = useState<SizeCategory[] | undefined>();
  const [filterList, setFilterList] = useState<FilterList | undefined>();
  const [searchKeyword, setSearchKeyword] = useState<string>("");
  const [userId, setUserId] = useState<string>("");
  const [blockScroll, allowScroll] = useScrollBlock();
  const [shopParamsBackup, setShopParamsBackup] = useState<ProductsListRequest | undefined>();
  const [resetShopFilter, setResetShopFilter] = useState<boolean>(false);
  const [homepagePopup, setHomepagePopup] = useState<HomepagePopupDTO[] | undefined>();

  useEffect(() => {
    if (asideChildren) {
      window.scroll(0, 0);
    }
  }, [asideChildren]);

  useEffect(() => {
    if (isMobile) {
      return;
    }
    if (asideIsOpen === null) {
      return;
    }
    if (!asideIsOpen) {
      allowScroll();
    } else {
      blockScroll();
    }
  }, [asideIsOpen]);

  const closeAndResetAside = () => {
    setAsideChildren(undefined);
    setAsideParams(undefined);
  };

  const openAsideMenu = <T,>(children: ReactNode, params: T) => {
    setAsideIsOpen(true), setAsideChildren(children);
    setAsideParams(params);
  };

  const addToast = (type: toastType, message: string) => {
    switch (type) {
      case toastType.error:
        toast.error(message, toastErrorStyle);
        return;
      case toastType.success:
        toast.success(message, toastSuccessStyle);
        return;
      case toastType.warning:
        toast.warning(message, toastWarningStyle);
        return;
    }
  };
  useEffect(() => {
    if (isPotraitView) {
      setIsPotrait(true);
    } else {
      setIsPotrait(false);
    }
  }, [isPotraitView]);
  useEffect(() => {
    if (isSmallDevice) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [isSmallDevice]);

  const genderFilter = [
    { label: t(i18Enum.User_UserProfile_Label_Gender_Male), value: GenderEnum.Male },
    { label: t(i18Enum.User_UserProfile_Label_Gender_Female), value: GenderEnum.Female },
    { label: t(i18Enum.User_UserProfile_Label_Gender_Unspecified), value: GenderEnum["Prefer Not to Answer"] },
  ];

  const cardsMenu: Options[] = [
    // {
    //   name: t(i18Enum.CardsMenu_1),
    //   value: "/shop",
    // },
    {
      name: t(i18Enum.CardsMenu_2),
      value: "/magazine",
    },
    {
      name: t(i18Enum.CardsMenu_3),
      value: "/community",
    },
  ];

  const appContextInitValue: AppGeneralContextProps = {
    isPotrait,
    isMobile,
    start,
    setStart,
    setIsMobile,
    addToast,
    page,
    setPage,
    pager,
    setPager,
    isLoading,
    setIsLoading,
    asideIsOpen,
    setAsideIsOpen,
    openAsideMenu,
    asideChildren,
    setAsideChildren,
    asideParams,
    setAsideParams,
    outfits,
    setOutfits,
    outfit,
    setOutfit,
    parsedSpicyProduct,
    setParsedSpicyProduct,
    homepageSliderContent,
    setHomepageSliderContent,
    sizeCategories,
    setSizeCategories,
    filterList,
    setFilterList,
    asideNavigation,
    asideCurrentPath,
    closeAndResetAside,
    genderFilter,
    cardsMenu,
    searchKeyword,
    setSearchKeyword,
    userId,
    setUserId,
    shopParamsBackup,
    setShopParamsBackup,
    resetShopFilter,
    setResetShopFilter,
    homepagePopup,
    setHomepagePopup
  };

  return <AppGeneralContext.Provider value={appContextInitValue}>{children}</AppGeneralContext.Provider>;
};
