import Image from "components/primitives/Image";
import { CustomThemeContext } from "context/ThemeContext";
import { ReactNode, useContext } from "react";

const CardTextStyle = `absolute text-6xl px-3 2xl:px-0 sm:text-7xl 2xl:text-9xl bottom-12 left-0 2xl:left-[-120px] sm:left-[-80px] font-semibold text-left leading-[75px] sm:leading-[108px] 2xl:leading-[192px] w-full sm:w-auto`;

const CardNoiseContainerStyle = `h-[441px] sm:h-[600px] sm:w-[400px] 2xl:h-[864px] w-[294px] 2xl:w-[576px] z-30 absolute rounded-[20px]`;
const CardImageContainerStyle = `h-[441px] sm:h-[600px] sm:w-[400px] 2xl:h-[864px] w-[294px] 2xl:w-[576px] z-20 absolute rounded-[20px]`;
const CardImageStyle = `h-[441px] sm:h-[600px] sm:w-[400px] 2xl:h-[864px] w-[294px] 2xl:w-[576px] rounded-[20px]`;

const EmojiBox = ({
  leftEmoji,
  rightEmoji,
  children,
  alt,
}: {
  leftEmoji: string;
  rightEmoji: string;
  children: ReactNode;
  alt?: boolean;
}) => (
  <div className="px-6">
    <div className="my-16 2xl:my-32 py-8 px-6 2xl:px-[30px] 2xl:py-10 relative 2xl:max-w-[640px] sm:max-w-[480px] sm:mr-0 sm:ml-auto">
      {alt ? (
        <>
          <div className="absolute z-20 top-1 right-0 text-5xl 2xl:text-6xl">
            {leftEmoji}
          </div>
          <div className="absolute z-20 bottom-1 left-0 text-5xl 2xl:text-6xl">
            {rightEmoji}
          </div>
        </>
      ) : (
        <>
          <div className="absolute z-20 top-2 left-0 text-5xl 2xl:text-6xl">
            {leftEmoji}
          </div>
          <div className="absolute z-20 bottom-2 right-0 text-5xl 2xl:text-6xl">
            {rightEmoji}
          </div>
        </>
      )}
      <div
        className="relative container-wip-outline h-40 2xl:h-[256px]"
        style={{ fontFamily: "BauProMedium" }}
      >
        <div className="text-2xl 2xl:text-5xl leading-[30px] 2xl:leading-[72px] py-[50px] 2xl:py-[56px]">
          {children}
        </div>
      </div>
    </div>
  </div>
);

const Wip = () => {
  const { customTheme } = useContext(CustomThemeContext);
  const colors = customTheme.colors;

  return (
    // padding top is 64px + 60px nav height only on mobile
    <div
      className="pt-[124px] 2xl:pt-32 max-w-lg 2xl:max-w-[1280px] lg:max-w-4xl mx-auto"
      style={{ fontFamily: "BauProBold" }}
    >
      <div className="relative w-full">
        <div className=" w-fit mx-auto sm:mx-0">
          <div
            className={`${CardNoiseContainerStyle} noise rotate-[-6deg]`}
          ></div>
          <div
            className={`${CardImageContainerStyle} rotate-[-6deg] glass-card`}
          ></div>
          <Image
            className={`${CardImageStyle} rotate-[-6deg]`}
            src="/images/wip1.jpg"
          />
        </div>
        <div className={`${CardTextStyle}`}>
          <div className="w-[366px] sm:w-[550px] 2xl:w-[832px] w-full w-fit mx-auto">
            <div
              className="whitespace-nowrap"
              style={{ color: colors.primaryBg }}
            >
              SIAMO
            </div>
            <div className="text-right" style={{ color: colors.primaryBg }}>
              FREEZATI
            </div>
          </div>
        </div>
      </div>

      {/* Emoji Box  */}
      <EmojiBox leftEmoji="❄️" rightEmoji="❤️‍🔥">
        PERCHÉ STA <br /> PER ARRIVARE
      </EmojiBox>

      <div className="relative">
        <div className=" w-fit mx-auto sm:mx-0">
        <div
            className={`${CardNoiseContainerStyle} noise rotate-6`}
          ></div>
          <div
            className={`${CardImageContainerStyle} rotate-6 glass-card-primary`}
          ></div>
          <Image
            className={`${CardImageStyle} rotate-6`}
            src="/images/wip2.jpg"
          />
        </div>
        <div className={`${CardTextStyle} z-20`}>
          <div className="w-[366px] sm:w-[550px] 2xl:w-[832px] w-full w-fit mx-auto mx-auto">
            <div
              className="whitespace-nowrap"
              style={{ color: colors.primaryBg }}
            >
              IL NUOVO
            </div>
            <div className="text-right" style={{ color: colors.primaryBg }}>
              DROP
            </div>
          </div>
        </div>
      </div>

      {/* Emoji Box  */}
      <EmojiBox leftEmoji="🔥" rightEmoji="😏" alt>
        CHE INCENDIERÀ <br />
        IL TUO STILE
      </EmojiBox>

      <div className="relative">
        <div className=" w-fit mx-auto sm:mx-0">
        <div
            className={`${CardNoiseContainerStyle} noise rotate-[-6deg]`}
          ></div>
          <div
            className={`${CardImageContainerStyle} rotate-[-6deg] glass-card-primary`}
          ></div>
          <Image
            className={`${CardImageStyle} rotate-[-6deg]`}
            src="/images/wip3.jpg"
          />
        </div>
        <div className={`${CardTextStyle} z-20`}>
          <div className="w-[366px] sm:w-[550px] 2xl:w-[832px] w-full w-fit mx-auto">
            <div
              className="whitespace-nowrap"
              style={{ color: colors.primaryBg }}
            >
              PRESTO
            </div>
            <div className="text-right" style={{ color: colors.primaryBg }}>
              ONLINE
            </div>
          </div>
        </div>
      </div>

      <div
        className="px-6 mb-[120px] sm:mb-[200px] mt-8 2xl:mt-[136px]"
        style={{ fontFamily: "BauProMedium" }}
      >
        <div className="py-8 px-6 relative 2xl:max-w-[640px] sm:max-w-[480px] sm:mr-0 sm:ml-auto">
          <div className="relative container-wip-outline h-40 2xl:h-[256px]">
            <div className="font-medium text-2xl 2xl:text-5xl uppercase leading-[30px] pt-[115px] 2xl:pt-[148px] 2xl:leading-[72px]">
              SEMPRE FUORI <br /> <br />
              DALL’ORDINARIO
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Wip;
