import { WardrobeItem, WardrobeItemType } from "utils/generalTypes/wardrobe";
import { CartItemType, ShopGroupType } from "utils/generalTypes/cartItems";
import * as c from "utils/const";
import { UserShippingAddress } from "redux/features/user/UserTypes";
import { UserPreference, UserType } from "redux/features/user/UserTypes";

export const objectIsNotEmpty = <T>(par: T | undefined) => {
  if (par && Object.keys(par).length !== 0) {
    return true;
  } else {
    return false;
  }
};

export const sortCards = (data: object[], cardsArray: object[][], numberOfItems: number) => {

  for (let i = 0; i < data.length; i += numberOfItems) {
    cardsArray.push(data.slice(i, i + numberOfItems));
  }

};

export const UserToUserPreference = (user: UserType) => {
  const req: UserPreference = {
    GivenName: user.GivenName,
    FamilyName: user.FamilyName,
    BirthDate: user.BirthDate,
    IsPrivate: user.IsPrivate,
    Gender: user.Gender,
    Picture: user.Picture,
    Height: user.Height,
    Weight: user.Weight,
    FavoriteColors: user.FavoriteColors,
    FavoriteSocials: user.FavoriteSocials,
    FavoriteSports: user.FavoriteSports,
    //FavoriteVibes: user.FavoriteVibes,
    FavoriteHobbies: user.FavoriteHobbies,
  }
  return req
}

export const waitNSeconds = (seconds: number) =>
  new Promise((resolve) => {
    setTimeout(() => {
      resolve(0);
    }, seconds * 1000);
  });

export const sortItemsByShop = (shops: ShopGroupType[], items?: CartItemType[]) => {
  const sorted = shops.map((shop) => {
    if (items) {
      return items.filter((product) => product.shopId === shop.shopId);
    }
  });
  return sorted
};

export function nullCheck(item?: any): boolean {
  return typeof (item) !== 'undefined' && (typeof (item) !== 'undefined' && item !== null);
}


export const parseWardrobeItems = (obj: WardrobeItem) => {
  return {
    id: obj.Id,
    imageUrl: obj.Variants?.[0]?.Images?.[0]?.Url,
    nameProduct: obj.Name,
    gender: undefined,
    categories: undefined,
    description: '',
    colors: [],
    isVisible: obj.Published,
  } as WardrobeItemType
}
export const retrieveUrlCode = (url: string) => {
  const code = url.match(/(.*?)(?=\?)/gm)?.[0].split('/')[4]
  if (!code?.length) {
    return ''
  }
  return code
}
export const retrieveShopId = (url: string) => {
  const shopId = url.match(/(\?shopId=)(.*)/)?.[2]
  if (!shopId?.length) {
    return ''
  }
  return shopId
}

export const setShippingAddressCall = (req: UserShippingAddress) => {

  req.IsBilling = false

}
export const setBillingAddressCall = (req: UserShippingAddress) => {

  req.IsBilling = true

}

export const truncate = (par:string, lenght:number) =>{
  if(par.length >= lenght){
    return par.substring(0, lenght) +"..."
  }
  return par
}

export const VariantColor = (key?: string) => {
  return c.colorsKeyEnum[key as keyof typeof c.colorsKeyEnum]
}