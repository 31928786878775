export function priceNum(num?: number): string {

  if (num === 0 || !num) {
    return "0"
  }
  
  // Redondea el número a dos decimales
  let roundedNumber = num?.toFixed(2) ?? "";

  // Usa toLocaleString para formatear el número según la configuración regional
  let formattedNumber = parseFloat(roundedNumber).toLocaleString("it-IT", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  return formattedNumber;
}
