import { useContext, useEffect, useMemo, useState } from "react";
import { CustomThemeContext } from "context/ThemeContext";
import { OptionsValue } from "utils";
import Flex from "components/primitives/Flex";
import Txt from "components/primitives/Txt";
import Grid from "components/primitives/Grid";
import Button from "components/primitives/Button";
import { AddressCard, addressType } from "components/molecules/AddressCard";
import MenuNavigateProps from "components/organisms/MenuNavigate";
import { useNavigate } from "react-router-dom";
import { AppGeneralContext } from "context/AppGeneralContext";
import { BillingPageEdit } from "./BillingPageEdit";
import { PAGE } from "utils/const/pagePath";
import { OrderType /*, PaymentStatus, UserType*/ } from "redux/features/user/UserTypes";
import { useAppSelector } from "redux/app/hooks";
import { useDispatch } from "react-redux";
import { getOrdersAction } from "redux/features/user/userSlice";
import { Order, StatusOrder } from "components/molecules/Order";
import { ErrorComponent } from "components/molecules";
import { OrderNoPayment } from "components/molecules/OrderNoPayment";
import { useTranslation } from "react-i18next";
import { i18Enum } from "i18n/types/translationType";

function OrdersPage() {
  const { customTheme } = useContext(CustomThemeContext);
  const colors = customTheme.colors;
  const { data:user, errors:userError} = useAppSelector((state) => state.user.user)
  const { data:billingAddress, errors: billingerror, isSuccess:billingIsSuccess } = useAppSelector((state) => state.user.userBillingAddresses)
  const { /*data: shippingAddress,*/ isSuccess:shippingIsSuccess } = useAppSelector((state) => state.user.userShippingAddresses)
  const { data:orders, errors:ordersError , isLoading, isSuccess } = useAppSelector((state) => state.user.userOrder)
  const { data: ordersParent } = useAppSelector((state) => state.user.userOrderParent)
  const { isMobile, openAsideMenu, setIsLoading } = useContext(AppGeneralContext);
  const [statusSelected, setStatusSelected]= useState<StatusOrder | undefined>(undefined)  
  const filteredOrders = useMemo(()=>statusSelected === undefined ? orders : FilterOrder(orders, statusSelected) ,[orders?.length,statusSelected])
 const { t } = useTranslation();
 const dispatch = useDispatch()
 const navigate = useNavigate()

 const filter: OptionsValue<StatusOrder | undefined>[] = [
   {
     name: t(i18Enum.Orders_OrderStatus_Filter_1),
     value: undefined,
   },
  //  {
  //    name: t(i18Enum.Orders_OrderStatus_Filter_2),
  //    value: StatusOrder.Canceled,
  //  },
   {
     name: t(i18Enum.Orders_OrderStatus_Filter_3),
     value: StatusOrder.Deleted,
   },
   {
     name: t(i18Enum.Orders_OrderStatus_Filter_4),
     value: StatusOrder.Pending,
   },
   {
     name: t(i18Enum.Orders_OrderStatus_Filter_5),
     value: StatusOrder.WaitingCustomer,
   },
   {
     name: t(i18Enum.Orders_OrderStatus_Filter_6),
     value: StatusOrder.Delivered,
   },
 ];

  useEffect(() => {
    
    if(shippingIsSuccess === null || isLoading ){
      return
    }
    setIsLoading(false)
  }, [shippingIsSuccess, isLoading])

  useEffect(() => {
    if(billingIsSuccess === null || isLoading){
      return
    }
    setIsLoading(false)
  }, [billingIsSuccess, isLoading])

  function FilterOrder(ordersData?:OrderType[] | null, status?: StatusOrder | undefined){
    if(!ordersData || status === undefined ){
      return []
    }
    return ordersData?.filter(x=> x.Status === status )
  }



  //TODO preparare con redux e type corretti
  
  
  useEffect(()=>setIsLoading(isLoading),[isLoading])

  function ReloadOrderPage(){
    dispatch(getOrdersAction())
  }

  // const handleAddBilling = () => {
  //   alert("Add Billing");
  // };

  const handleFilterOrder = (option: OptionsValue<StatusOrder | undefined>) => {
    setStatusSelected(option.value);
  };

  const handleAddEditBilling = (id: string | undefined): void => {
    if (isMobile) {
      navigate(PAGE.billing.menuPath, { state: { id: id ? id : undefined } })
    }
    openAsideMenu<string | undefined>(<BillingPageEdit />, id ? id : undefined)

  };

  return (
    <Flex padding={["5% 0", "5% 0", "0"]} width={[1]} justifyContent={"center"}>
      <Flex flexDirection={"column"} width={[1]} padding={["0 1em", "0 5em"]} $gap={1.2} maxWidth={["none", 1100]}>
        {user && <MenuNavigateProps userData={user} />}
        {isSuccess && (
          <>
            <Flex justifyContent={"space-between"} alignItems={"center"} marginTop={"1em"}>
              <Txt as={"h3"} variant='light' fontSize={[3]} color={colors.thirdText} textAlign={"left"}>
                {t(i18Enum.Orders_BillingAddress_Title)}
              </Txt>
              <Txt variant='linkLight' color={colors.primaryText} fontSize={[3]} $cursor='pointer' onClick={() => handleAddEditBilling(undefined)}>
                {t(i18Enum.Orders_BillingAddress_AddNow)}
              </Txt>
            </Flex>

            {billingAddress && billingAddress.length !== 0 ? (
              <Grid marginBottom={[5]} style={{ rowGap: "1em", columnGap: "1em" }} gridTemplateColumns={["repeat(1, 1fr)", "repeat(1, 1fr)", "repeat(2, 1fr)"]}>
                {billingAddress.map((billing, index) => (
                  <AddressCard
                    addressId={billing.Id}
                    key={index}
                    type={addressType.userBilling}
                    Name={billing.Name ?? ""}
                    PI={billing.PI}
                    CF={billing.CF}
                    City={billing.City ?? ""}
                    AddressLine1={billing.AddressLine1 ?? ""}
                    AddressLine2={billing.AddressLine2 ?? ""}
                    ZipCode={billing.ZipCode ?? ""}
                    CountryKey={billing.CountryKey ?? 110}
                    isDefault={billing.IsDefault ?? false}
                    handleEdit={() => {
                      handleAddEditBilling(billing.Id);
                    }}
                    isBilling={billing.IsBilling ?? true}
                  />
                ))}
              </Grid>
            ) : (
              <Txt marginTop={0} as={"p"} variant='light' color={colors.thirdText} textAlign={"left"} $textTransform='inherit' fontSize={[3]}>
                {t(i18Enum.Orders_BillingAddress_NoAddress)}
                <Txt paddingLeft={[3]} variant='linkLight' color={colors.primaryText} fontSize={[3]} onClick={() => handleAddEditBilling(undefined)}>
                  {t(i18Enum.Orders_BillingAddress_AddNow)}
                </Txt>
              </Txt>
            )}
            {ordersParent && ordersParent.length !== 0 && (
              <>
                <Txt fontSize={[3]} as={"h3"} variant='light' color={colors.thirdText} textAlign={"left"} marginBottom={0}>
                  {t(i18Enum.Orders_Order_Pending_Title)}
                </Txt>
                <Grid width={"100%"} marginTop={[3]} gridTemplateColumns={["repeat(1, 1fr)", "repeat(1, 1fr)", "repeat(2, 1fr)"]} style={{ rowGap: "1em", columnGap: "1em" }}>
                  {ordersParent
                    .filter((order) => order.Status === 0)
                    .map((order) => (
                      <OrderNoPayment
                        key={order.Id}
                        Id={order.Id}
                        ShopId={order.ShopId}
                        ShopName={order.ShopName}
                        Total={order.Total}
                        Products={order.Products}
                        ItemsTotal={order.Total}
                        Number={order.Number}
                        Status={order.Status ?? 0}
                        StripeCheckoutUrl={order.StripeCheckoutUrl ?? ""}
                        ParentNumber={order.ParentNumber}
                      />
                    ))}
                </Grid>
              </>
            )}

            <Txt fontSize={[3]} as={"h3"} variant='light' color={colors.thirdText} textAlign={"left"} marginBottom={0}>
              {t(i18Enum.Orders_Title)}
            </Txt>

            {orders?.length !== 0 && (
              <Flex $gap={0.5} flexWrap={"wrap"}>
                {filter.map((button, index) => (
                  <Button
                    key={index}
                    type='button'
                    variant={button.value === statusSelected ? "option" : "switcherGenre"}
                    padding={["0.5em 2em"]}
                    minWidth={"70px"}
                    width={"auto"}
                    onClick={() => handleFilterOrder(button)}
                  >
                    <Txt $cursor='pointer' fontSize={["10px", "12px"]}>
                      {button.name}
                    </Txt>
                  </Button>
                ))}
              </Flex>
            )}

            {filteredOrders && filteredOrders.length > 0 ? (
              <Grid width={[1]} marginTop={[3]} gridTemplateColumns={["repeat(1, 1fr)", "repeat(2, 1fr)"]} style={{ rowGap: "1em", columnGap: "1em" }}>
                {filteredOrders?.map((order) => (
                  <Order
                    key={order.Id}
                    Id={order.Id}
                    ShopId={order.ShopId}
                    ShopName={order.ShopName}
                    Total={order.Total}
                    Products={order.Products}
                    ItemsTotal={order.ItemsTotal}
                    Number={order.Number}
                    Status={order.Status ?? 0}
                    ParentNumber={order.ParentNumber}
                  />
                ))}
              </Grid>
            ) : (
              <Txt marginTop={0} as={"p"} variant='light' color={colors.thirdText} textAlign={"left"} $textTransform='inherit' fontSize={[3]}>
                {t(i18Enum.Orders_Message_NoOrders)}
              </Txt>
            )}
          </>
        )}
        {!isSuccess && isSuccess !== null && !isLoading && ordersError && <ErrorComponent errors={ordersError} textToDisplay={t(i18Enum.ErrorPage500_ErrorLabel)} onClickHandler={ReloadOrderPage} />}
      </Flex>
    </Flex>
  );
}

export default OrdersPage;
