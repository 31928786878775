import { getProductByIdCall, getProductFromVariant } from "api/contentCalls/productCalls";
import ProductCardImage from "components/atoms/ProductCardImage";
import Flex from "components/primitives/Flex";
import Section from "components/primitives/Section";
import Txt from "components/primitives/Txt";
import { AppGeneralContext } from "context/AppGeneralContext";
import { CustomThemeContext } from "context/ThemeContext";
import { i18Enum } from "i18n/types/translationType";
import { useContext, useEffect, useMemo, useState } from "react";
import Marquee from "react-fast-marquee";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "redux/app/hooks";
import { ProductImageDTO, ProductType } from "redux/features/product/ProductsTypes";
import { ProductActions } from "redux/features/product/productSlice";
import { PAGE } from "utils/const/pagePath";
import { toastType } from "utils/generalTypes/enum";

interface OutfitMarqueeProp {
  title: string;
  images: ProductImageDTO[];
  description: string;
  maxAngle?: number;
  minAngle?: number;
  products?: ProductType[] | undefined;
}

export const OutfitMarquee = ({ title, images, description, maxAngle, minAngle }: OutfitMarqueeProp) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { customTheme } = useContext(CustomThemeContext);
  const { isMobile, addToast } = useContext(AppGeneralContext);
  const color = customTheme.colors;
  const maxW = customTheme.maxWidth;
  const max = maxAngle ?? 12;
  const min = minAngle ?? 5;
  const [angle, setAngle] = useState<number>(max);

  //   const fetchProductFromVariant = async (id: string) => {
  //     const resp = await getProductFromVariant(id);
  //     if (!resp.isSuccess) {
  //       addToast(toastType.error, t(i18Enum.Error_Occurred));
  //     } else {
  //       console.log("a", resp.response);
  //       dispatch(ProductActions.getSimilarProductAction(resp.response?.Data?.Id ?? ""));
  //       navigate(`${PAGE.product.menuPath}/${resp.response?.Code}`, { state: resp.response?.Id });
  //     }
  //   };

  //   const fetchProductById = async (id: string) => {
  //     const resp = await getProductByIdCall(id);
  //     if (!resp.isSuccess) {
  //       addToast(toastType.error, t(i18Enum.Error_Occurred));
  //     } else {
  //       console.log("b", resp.response);
  //       dispatch(ProductActions.getSimilarProductAction(resp.response?.Id ?? ""));
  //       navigate(`${PAGE.product.menuPath}/${resp.response?.Code}`, { state: resp.response?.Id });
  //     }
  //   };

  //   const goToProduct = (card: ProductImageDTO) => {
  //     if (card.VariantId) {
  //       fetchProductFromVariant(card.VariantId);
  //     } else if (card.ProductId) {
  //       fetchProductById(card.ProductId);
  //     } else {
  //       addToast(toastType.error, t(i18Enum.Error_Occurred));
  //     }
  //   };

  const goToProduct = async (card: ProductImageDTO) => {
    const resp = await getProductFromVariant(card.VariantId ?? "");
    if (!resp.isSuccess) {
      const fromProductResp = await getProductByIdCall(card.ProductId ?? "");
      navigate(`${PAGE.product.menuPath}/${fromProductResp.response?.Code}`, { state: fromProductResp.response?.Id });
    }

    if (resp.response?.Data) {
      dispatch(ProductActions.setProductAction(resp.response.Data));
      navigate(`${PAGE.product.menuPath}/${resp.response?.Data?.Code}`, { state: resp.response.Data.Id });
      return;
    }
    addToast(toastType.error, t(i18Enum.Error_Occurred));
  };

  useEffect(() => {
    const currentWindowWidth = window.innerWidth;
    CalculatingAngle(currentWindowWidth);
  }, [window.innerWidth]);

  useEffect(() => {
    const resizeHandler = () => {
      const currentWindowWidth = window.innerWidth;
      CalculatingAngle(currentWindowWidth);
    };
    window.addEventListener("resize", resizeHandler);
    return () => window.removeEventListener("resize", resizeHandler);
  }, [window.innerWidth]);

  function CalculatingAngle(width: number) {
    if (width <= 768) {
      setAngle(max);
    } else if (width >= 1300) {
      setAngle(min);
    } else {
      const range = 1300 - 768;
      const newAngle = max - ((width - 768) * (max - min)) / range;
      setAngle(newAngle);
    }
  }

  const showOutfitImage = useMemo(() => {
    return (
      <Flex>
        {images.map((card, i) => {
          return (
            <Flex key={i} onClick={() => goToProduct(card)}>
              <ProductCardImage minWidth={"150px"} $aspectRatio='9/16' $backgroundImage={card.Url} $animated={!isMobile} />
            </Flex>
          );
        })}
      </Flex>
    );
  }, [images]);

  return (
    <Section width={[1]} flexDirection={"column"} overflowX={"hidden"} alignItems={"center"}>
      <Flex
        width={[1]}
        margin={[`calc( 100vw * tan(${angle}deg)) 0 calc( 100vw * tan(${angle}deg) / 2) 0 `]}
        style={{ transform: `rotate(-${angle}deg) scale(1.3)` }}
        $transition='all 2s'
        flexDirection={"column"}
      >
        <Marquee direction='left' loop={0}>
          <Txt as={"h2"} color={color.primaryBase}>
            {(title + " ").repeat(10)}
          </Txt>
        </Marquee>
        <Marquee direction='right' loop={0}>
          {showOutfitImage} {showOutfitImage} {showOutfitImage} {showOutfitImage}
        </Marquee>
        <Marquee direction='left' loop={0}>
          <Txt as={"h2"} color={color.primaryBase}>
            {(title + " ").repeat(10)}
          </Txt>
        </Marquee>
      </Flex>
      <Flex width={[1]} maxWidth={[null, maxW[0]]} flexDirection={"row"} padding={["0 5%", "0 2%"]} justifyContent={"end"}>
        <Txt textAlign={"right"} fontSize={[3, 5]} variant='light' width={["45%", "40%"]} $textTransform='none'>
          {description}
        </Txt>
      </Flex>
    </Section>
  );
};
