
import { LoginRequest, VerifyForgotPswRequest } from "./types/requests";
import { BaseResponse, CoreResponse, LoginResponse } from "./types/responses";
import { getAriesCall, postAriesCall, patchAriesCall } from "./baseAriesCall";

const FORGOT_PSW_CONTROLLER = "Auth/ForgotPswCode";
const VERIFY_PSW_CONTROLLER = "Auth/VerifyForgotPsw";

//TODO not Implemented
export const getAuthCall = async (user:LoginRequest) => await postAriesCall<LoginRequest, LoginResponse>(`Auth/Login`,user)

export const getForgotPasswordCode = async (email: string) => await getAriesCall<BaseResponse<string>>(`${FORGOT_PSW_CONTROLLER}/${email}`);

export const verifyForgotPswCall = async (req: VerifyForgotPswRequest) => await patchAriesCall<VerifyForgotPswRequest, CoreResponse>(VERIFY_PSW_CONTROLLER, req);






