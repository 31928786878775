

export enum toastType {
  success,
  error,
  warning,
}
export enum TitleEnum {
  nowLoading = "Loading...",
  error = "Error",
  success = "Success",
  message = "New Message",
}

export enum ModalTypeEnum {
  popup = "popup",
  message = "message",
  uploadImage = "uploadImage",
}

export enum SearchKey {
  ProductsShopCard,
  ProductsSearch,
  WardrobeItemsUserCard,
  WardrobeItemsOuttfitSearch,
  WardrobeItemsJobWorkOutfit,
}

export enum ShopStatusEnum {
  Draft,
  InApproval,
  Approved,
}

export enum GenderEnum {
  "Prefer Not to Answer" = -1,
  "Female" = 0,
  "Male" = 1,
}

export enum CategoriesEnum {
  Gender = "Gender",
  Colors = "Colors",
  Sizes = "Sizes",
}
