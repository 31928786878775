import { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Section from "components/primitives/Section";
import Box from "components/primitives/Box";
import ArrowSvg from "components/atoms/ArrowSvg";
import Txt from "components/primitives/Txt";
import Flex from "components/primitives/Flex";
import { MobileNavigationBottom } from "components/atoms/MobileNavigationBottom";
import { ShippingCard } from "components/organisms/ShippingCard";
import { priceNum } from "utils";
import { AppGeneralContext } from "context/AppGeneralContext";
import { Cart } from "./Cart";
import { PAGE } from "utils/const/pagePath";
import { ShippingAddress } from "./ShippingAddress";
import { useAppDispatch, useAppSelector } from "redux/app/hooks";
import { useTheme } from "styled-components";
import { SetShippingOptionRequest, UpdateShippingRequest } from "api/types/requests/cartRequest";
import { cartActions } from "redux/features/cart/cartSlice";
import { i18Enum } from "i18n/types/translationType";
import { useTranslation } from "react-i18next";
import { filterItems } from "utils/functions/filterItems";

export const ShippingMethod = () => {
  //context data
  const { isMobile, openAsideMenu } = useContext(AppGeneralContext);
  //hook assignment
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const colors = useTheme().colors;
  //selectors
  const shoppingBasket = useAppSelector((state) => state.cart.cart.data?.ShoppingBasket);
  const { isLoading } = useAppSelector((state) => state.cart.cart);
  const totalPrice = useAppSelector((state) => state.cart.cart.data?.ShoppingBasket?.TotalPrice);
  //useState data
  const [disabled, setDisabled] = useState<boolean>(true);
  const { t } = useTranslation();

  const [shopsTotals, setShopsTotals] = useState<UpdateShippingRequest[]>([]);
  const [totalCartPrice, setTotalCartPrice] = useState<number>(totalPrice!);

  useEffect(() => {
    if (!shoppingBasket?.Shops || !totalCartPrice) {
      return;
    }

    const beginCheckoutItems = filterItems(shoppingBasket?.Shops);
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
      event: "begin_checkout",
      ecommerce: {
        currency: "EUR",
        value: totalCartPrice,
        items: beginCheckoutItems,
      },
    });

    console.log({ beginCheckout: beginCheckoutItems });
  }, [shoppingBasket?.Shops, totalCartPrice]);

  //handler Function
  const handleShopTotal = (req: UpdateShippingRequest) => {
    const newArr = shopsTotals;
    const idx = newArr?.findIndex((x) => x.ShopId === req.ShopId);
    if (idx !== -1) {
      newArr.splice(idx, 1);
    }
    const newOpt = { ...req };
    setShopsTotals((prev) => [...prev, newOpt]);
    return;
  };

  const retrieveTotalPrice = (arr: UpdateShippingRequest[]) => {
    const price = arr.map((el) => el.price).reduce((acc, current) => acc + current, 0);
    return price;
  };

  useEffect(() => {
    if (shopsTotals.length <= 0) {
      return;
    }
    setTotalCartPrice(retrieveTotalPrice(shopsTotals));
    if (shopsTotals.length === shoppingBasket?.Shops?.length) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [shopsTotals]);

  const navigationText = (condition: boolean) => {
    if (condition) {
      return `*${t(i18Enum.Cart_Checkout_ShippingIncluded)}`;
    }

    return `*${t(i18Enum.Cart_Checkout_ShippingNotIncluded)}`;
  };

  //Navigation Function
  function back() {
    if (isMobile) {
      navigate(-1);
    }
    openAsideMenu<undefined>(<Cart />, undefined);
  }
  //function called in cartSaga, when setShippingOption is success
  function navigationAfterSuccess() {
    if (isMobile) {
      navigate(PAGE.shippingAddress.menuPath);
      return;
    }
    openAsideMenu<undefined>(<ShippingAddress />, undefined);
  }
  const next = useCallback(() => {
    const req: SetShippingOptionRequest = {
      ShoppingBasketId: shoppingBasket?.Id ?? "",
      ShopsShippings: shopsTotals,
    };
    dispatch(cartActions.setShippingOptionAction({ req: req, action: navigationAfterSuccess }));
  }, [shoppingBasket?.Id, shopsTotals]);

  return (
    <>
      <Flex width={"100%"} flexDirection={"column"} alignItems={"center"} justifyContent={"start"} padding={["12% 0", "5% 0", "0"]}>
        <Box maxWidth={1100} width={"100%"}>
          <Section flexDirection={"column"} justifyContent={"center"} padding={"2em"} width={"100%"}>
            <ArrowSvg widthCustom='s' position={"absolute"} left={[3]} handleClick={() => back()} />
            <Txt fontSize={["12px", "14px"]} variant='medium' color={colors.primaryBase}>
              {t(i18Enum.Cart_ShippingMethod_Title)}
            </Txt>
          </Section>
          {/* {isLoading && <LoadingSpinner dimension="40px" />} */}
          {shoppingBasket?.Shops?.length && shoppingBasket?.Shops?.length > 0 ? (
            shoppingBasket?.Shops?.map((shop) => {
              const existingShopSelected = shoppingBasket?.Shops?.find((x) => x.Id === shop.Id);
              const shopSelected =
                shop?.ShippingOptionsList && existingShopSelected?.ShippingOption ? shop?.ShippingOptionsList.find((x) => x.Id === existingShopSelected?.ShippingOption.Id) : undefined;

              return (
                <ShippingCard
                  key={shop.Id}
                  handleShopTotal={handleShopTotal}
                  shippingOptionSelect={shopSelected}
                  shippingOptionsList={shop.ShippingOptionsList}
                  id={shop.Id}
                  shopItems={shop.Variants}
                  shop={shop}
                />
              );
            })
          ) : (
            // TODO: aggiungere il testo per indicare che il carrello è vuoto
            <Txt>{t(i18Enum.Cart_Checkout_NoItems)}</Txt>
          )}
        </Box>
      </Flex>

      <MobileNavigationBottom
        variant='cart'
        disabled={shopsTotals.length !== shoppingBasket?.Shops?.length}
        isLoading={isLoading}
        cartShippingInfo={navigationText(shopsTotals.length === shoppingBasket?.Shops?.length)}
        text={t(i18Enum.Common_Next)}
        description={`${t(i18Enum.Common_Total)}: ${priceNum(totalCartPrice)}€`}
        buttonAction={() => next()}
      />
    </>
  );
};
