import { Outlet, useNavigate } from "react-router-dom";
import Flex from "../components/primitives/Flex";
import { useEffect } from "react";
import { useAppSelector } from "redux/app/hooks";
import { PAGE } from "utils/const/pagePath";

export const ProtectedPage = () => {
  const {data} = useAppSelector((state)=>state.auth.auth)
  const navigate = useNavigate()
  
  useEffect(()=>{
    if(data === null){
     navigate(PAGE.home.menuPath)
    }
  },[data])

  return (
    <Flex height={"100%"} flexDirection={"column"} width={[1]}>
      <Outlet />
    </Flex>
  );
};
