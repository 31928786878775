import { ChangeEvent, useContext, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import InputFieldWardrobe from "components/molecules/InputFieldWardrobe";
import Button from "components/primitives/Button";
import Flex from "components/primitives/Flex";
import Txt from "components/primitives/Txt";
import { useTranslation } from "react-i18next";
import { i18Enum } from "i18n/types/translationType";
import { VerifyUserType } from "redux/features/user/UserTypes";
import { verifyRegisterCall } from "api/userCalls/userCalls";
import { CustomThemeContext } from "context/ThemeContext";
import BackgroundVideo from "components/molecules/VideoBackgroud";
import { AppGeneralContext } from "context/AppGeneralContext";
import { toastType } from "utils";
import { DotsLoader } from "components/molecules/DotsLoader";
import { useAppSelector } from "redux/app/hooks";
import { PAGE } from "utils/const/pagePath";

export const VerifyUserPage = () => {
  const [params, setParams] = useSearchParams();
  const queryUserId = params.get("id");
  const queryCode = params.get("code");
  const { customTheme } = useContext(CustomThemeContext);
  const { addToast, setUserId, userId } = useContext(AppGeneralContext);
  const user = useAppSelector((state) => state.user.user.data);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [registerCode, setRegisterCode] = useState<string>(queryCode ?? "");
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (!queryCode) {
      return;
    }
    setRegisterCode(queryCode);
  }, [queryCode]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setRegisterCode(value);
  };

  const retrieveId = () => {
    if (queryUserId) {
      return queryUserId;
    }
    if (userId) {
      return userId;
    }
    if (user?.Id) {
      return user.Id;
    }
  };

  const verifyAccount = async () => {
    const uId = retrieveId();
    if (!uId) {
      addToast(toastType.error, t(i18Enum.ErrorPage_PleaseRetry));
      return;
    }
    setLoading(true);
    const req: VerifyUserType = { Id: uId, Code: registerCode };
    const resp = await verifyRegisterCall(req);
    if (!resp.isSuccess) {
      addToast(
        toastType.error,
        resp.error === "-2"
          ? t(i18Enum.VerifyAccount_ErrorMessage_Wrong)
          : resp.error === "-3"
          ? t(i18Enum.VerifyAccount_ErrorMessage_Expired)
          : t(i18Enum.ForgotPsw_ErrorMessage)
      );
    } else {
      navigate(PAGE.login.menuPath);
      setUserId("");
    }
    setLoading(false);
  };

  return (
    <>
      <Flex
        width={[1]}
        height={["100%"]}
        maxHeight={["100vh"]}
        maxWidth={customTheme.maxWidth[0]}
        position={"relative"}
        alignItems="center"
        marginTop={["100px", 0]}
        justifyContent={"left"}
      >
        <Flex
          flexDirection={"column"}
          alignItems={"start"}
          justifyContent={["normal", "center"]}
          minHeight={"calc(100vh - 200px)"}
          width={[1, 1, 0.5]}
          padding={["40px 28px 40px"]}
          $gap={4}
          style={{ boxSizing: "border-box" }}
        >
          <Txt
            width={[1, 0.75, 1]}
            variant="light"
            fontSize={[3]}
            textAlign={"end"}
          >
            {t(i18Enum.VerifyAccount_Desc)}
          </Txt>
          <Flex flexDirection={"column"} width={["70%", "40%", "50%"]} $gap={1}>
            <InputFieldWardrobe
              id={"Code"}
              placeholder={t(i18Enum.Register_Label_ValidationCode)}
              spaceProp={{ width: 1, marginTop: [2], padding: ["10px 0"] }}
              value={registerCode}
              onChange={(e) => handleChange(e)}
            />
            <Button variant="login" onClick={verifyAccount}>
              {loading ? (
                <DotsLoader
                  customHeight="22px"
                  colors={customTheme.colors.secondaryBase}
                />
              ) : (
                t(i18Enum.Affiliate_Button_Send)
              )}
            </Button>
          </Flex>
        </Flex>
      </Flex>
      <BackgroundVideo
        video="first"
        variant={"left"}
        height={"100vh"}
        top={0}
        width={[0.9, 0.9, 0.5]}
      />
    </>
  );
};
