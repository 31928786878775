import { useContext, useEffect, useState } from "react";
import Button from "../primitives/Button";
import CartSvg from "../atoms/CartSvg";
import Txt from "../primitives/Txt";
import { CustomThemeContext } from "../../context/ThemeContext";
import { ProductProps } from ".";
import SelectColor from "../molecules/SelectColor";
import Accordion from "../molecules/Accordion";
import { MultiColorKeyGradient, VariantColor, colorsKeyEnum } from "../../utils";
import SelectSize from "../molecules/SelectSize";
import Flex from "../primitives/Flex";
import Skeleton from "../atoms/Skeleton";
import { i18Enum } from "i18n/types/translationType";
import { useTranslation } from "react-i18next";
import { DotsLoader } from "components/molecules/DotsLoader";
import { RenderHTML } from "components/primitives/RenderHTML";
import Tooltip from "components/primitives/Tooltip";

export const ProductDetailDesktop = ({ cartIsUpdating, isBuyNow, product, selected, handleSelection, handleAddToCart, isLoading, productVariants, productToBasket }: ProductProps) => {
  const { customTheme } = useContext(CustomThemeContext);
  const colors = customTheme.colors;
  const [openedAccordion, setOpenedAccordion] = useState<string>("shipping");
  const [selectedColorId, setSelectedColorId] = useState<string | undefined>(undefined);
  const { t } = useTranslation();
  const accordionList = [
    { id: "info", title: t(i18Enum.Product_Label_Details), description: product?.Description, defaultOpen: false },
    { id: "material", title: t(i18Enum.Product_Label_MaterialAndCare), description: product?.Composition, defaultOpen: false },
    {
      id: "shipping",
      title: t(i18Enum.Product_Label_ShippingInfo),
      description: t(i18Enum.Product_Free_ShippingInfo),
      defaultOpen: true,
    },
  ];

  useEffect(() => {
    const viewItem = [
      {
        item_id: selected?.Id,
        item_name: selected?.ProductName,
        item_variant: selected?.Sku,
      },
    ];

    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
      event: "view_item",
      ecommerce: {
        currency: "EUR",
        value: selected?.DiscountedPrice ? selected?.DiscountedPrice : selected?.Price,
        items: viewItem,
      },
    });
    console.log({ viewItem: viewItem });
  }, [selected]);

  useEffect(() => {
    if (selected) {
      setSelectedColorId(selected?.Colors?.[0]?.Id);
    }
  }, [selected]);

  useEffect(() => {
    if (!productVariants?.length) {
      handleSelection(selected !== null ? selected : undefined);
    }
  }, []);

  const showColorSection = productVariants?.some((variant) => variant?.Colors && variant?.Colors?.length > 0) ?? false;

  return (
    <Flex $gap={1} height={"100%"} flexDirection={["column", "row", "column"]} paddingTop={["0px", "20px", "0px"]} justifyContent={["space-between"]}>
      <Flex flexDirection={"column"} padding={"1em"} border={"1px solid white"} width={["100%", "50%", "100%"]}>
        <Flex p={"1px 5px"} flexDirection={"column"}>
          {/* color */}
          <Flex flexDirection={"column"}>
            {showColorSection ? (
              <>
                <Txt width={[1]} fontSize={[1]} color={colors.thirdBase} textAlign={"left"} variant='light' mb={1}>
                  {t(i18Enum.Product_SelectColor)}
                </Txt>
                <Flex mb={3} $gap={1} flexDirection={"row"} flexWrap={"wrap"} width={"100%"}>
                  {!isLoading ? (
                    productVariants?.map((variant) => {
                      return variant.Colors?.map((tag) => {
                        return (
                          <Tooltip content={tag.Label ?? ""} key={tag?.Id}>
                            <SelectColor
                              isBlack={false}
                              background={tag?.Label === colorsKeyEnum.Multicolour ? MultiColorKeyGradient : VariantColor(tag?.Label ?? "")}
                              selected={selectedColorId === tag?.Id}
                              onClick={() => {
                                handleSelection(variant);
                                setSelectedColorId(tag.Id);
                              }}
                            />
                          </Tooltip>
                        );
                      });
                    })
                  ) : (
                    <Skeleton width={"100%"} height={"20px"} />
                  )}
                </Flex>
              </>
            ) : null}
          </Flex>
          {/*sizes  */}
          {isLoading ? (
            <Skeleton height={"80px"} width={"100%"} />
          ) : (
            <SelectSize variant='xl' title={t(i18Enum.Product_SelectSize)} productVariants={productVariants ?? []} selected={selected} productToBasket={(product) => productToBasket(product)} />
          )}
          {/* infoBuyNow */}
          <Txt mt={2} color={colors.primaryText} fontSize={[2]} variant='light' paddingBottom={"10px"} style={{ textTransform: "inherit" }}>
            {/* {capitalize(product. ?? "")} */}
          </Txt>
        </Flex>
        {/* addToCart button */}
        <Button variant='addToCart' padding={"13px 0"} onClick={() => handleAddToCart?.(false)}>
          <CartSvg width={"1.1428em"} height={"1.4285em"} />

          <Txt fontFamily={"BauProMedium"} fontWeight={500} fontSize={[3]} marginLeft={"10px"}>
            {cartIsUpdating && !isBuyNow ? <DotsLoader customHeight='23px' /> : t(i18Enum.Product_ProductCard_Button_AddToCart)}
          </Txt>
        </Button>
        {/* BUY NOW button */}
        <Button variant='buyNow' padding={"13px 0"} onClick={() => handleAddToCart?.(true)}>
          <CartSvg width={"1.1428em"} height={"1.4285em"} />
          <Txt fontFamily={"BauProMedium"} fontWeight={500} fontSize={[3]} marginLeft={"10px"}>
            {cartIsUpdating && isBuyNow ? <DotsLoader customHeight='23px' colors={colors.secondaryBase} /> : t(i18Enum.Product_Button_BuyNow)}
          </Txt>
        </Button>
      </Flex>
      {/* ACCORDION */}
      <Flex flexDirection={"column"} width={["100%", "50%", "100%"]}>
        {accordionList.map((accordion) => (
          <Accordion onOpenHandler={() => setOpenedAccordion(accordion.id)} key={accordion.id} variant={"card"} title={accordion.title} defaultOpen={accordion.id === openedAccordion}>
            {accordion.id === "shipping" ? (
              <RenderHTML variant='unordered' html={accordion.description ?? ""} fontSize={"12px"} />
            ) : (
              <Txt as='p' variant='light' color={colors.thirdBase} fontSize={[2]} margin='0' style={{ textTransform: "inherit" }}>
                {accordion.description ?? ""}
              </Txt>
            )}
          </Accordion>
        ))}
        {/* <Accordion onOpenHandler={() => setOpenedAccordion("info")} variant={"card"} title={"info"} defaultOpen={openedAccordion === "info"}>
          <Txt as='p' variant='light' color={colors.thirdBase} fontSize={[2]} style={{ textTransform: "inherit" }} margin='0'>
            {capitalize(product?.info ?? "")}
          </Txt>
        </Accordion>
        <Accordion onOpenHandler={() => setOpenedAccordion("material")} variant={"card"} title={"material"} defaultOpen={openedAccordion === "material"}>
          <Txt as='p' variant='light' color={colors.thirdBase} fontSize={[2]} style={{ textTransform: "inherit" }} margin='0'>
            {capitalize(product?.material ?? "")}
          </Txt>
        </Accordion>
        <Accordion onOpenHandler={() => setOpenedAccordion("shipping")} variant={"card"} title={"shipping"} defaultOpen={openedAccordion === "shipping"}>
          <Txt as='p' variant='light' color={colors.thirdBase} fontSize={[2]} style={{ textTransform: "inherit" }} margin='0'>
            {capitalize(product?.shipping ?? "")}
          </Txt>
        </Accordion> */}
      </Flex>
    </Flex>
  );
};
