import { ProductType } from "redux/features/product/ProductsTypes";
import { BaseResponse, PaginatedResponse } from "../types/responses";
import { ProductsListRequest } from "api/types/requests/productRequest";

import { getAriesCall, postAriesCall } from "api/baseAriesCall";

import { getProducts } from "api/graphql/requests";

import {queryClient} from "App";

const PRODUCT_CONTROLLER = "Product";


export const productSearchCall = async (params: ProductsListRequest) => {
  try {
    const data = await queryClient.fetchQuery({queryKey:['products'], queryFn: ()=>getProducts(params)});
    return {response: data, isSuccess:true, error:false};
  } catch (error) {
    console.error(error);
    return {response: null, isSuccess:false, error};
  }
};

// export const productSearchCall = async (params: ProductsListRequest) => {
//   const req: ProductsListRequest = { ...params, IsFiltering: undefined };
//   return await postAriesCall<ProductsListRequest, PaginatedResponse<ProductType>>(`${PRODUCT_CONTROLLER}/Search`, req);
// };

export const getSimilarProductCall = async (id: string) => await getAriesCall<ProductType>(`${PRODUCT_CONTROLLER}/${id}/Similar`);

export const getProductByIdCall = async (id: string) => await getAriesCall<ProductType>(`${PRODUCT_CONTROLLER}/${id}`);

export const getProductIdFromCode = async (prodCode: string) => await getAriesCall<BaseResponse<string>>(`${PRODUCT_CONTROLLER}/GetByCode/${prodCode}`);

export const getProductFromVariant = async (id: string) => await getAriesCall<BaseResponse<ProductType>>(`${PRODUCT_CONTROLLER}/FromVariant/${id}`);
