import { useContext, useEffect, useState } from "react";
import { HeightProps, SpaceProps } from "styled-system";
import { CustomThemeContext } from "../../context/ThemeContext";
import Txt from "../primitives/Txt";
import Button from "../primitives/Button";
import Flex from "../primitives/Flex";
import { useTranslation } from "react-i18next";
import { Tags, VariantDTO } from "redux/features/product/ProductsTypes";
import { SelectedProduct } from "utils/generalTypes/Frontend";
import { useAppSelector } from "redux/app/hooks";
import { i18Enum } from "i18n/types/translationType";

interface SelectSizeProps extends SpaceProps, HeightProps {
  variant: "s" | "xl";
  title: string;
  productVariants: VariantDTO[];
  selected?: VariantDTO | null;
  productToBasket: (SelectedProduct: SelectedProduct) => void;
}

function SelectSize({ ...props }: SelectSizeProps) {
  const { customTheme } = useContext(CustomThemeContext);
  const colors = customTheme.colors;
  const { t } = useTranslation();
  const [selectedTagId, setSelectedTagId] = useState<string | null>(null);
  const isLoading = useAppSelector((state) => state.cart.cart.isLoading);

  const isOneSize = props.selected?.Sizes?.length === 0 || props.selected?.Sizes?.length === 1;

  const retrieveSelection = (selectedProd?: VariantDTO | null, tagSelected?: Tags, isOneSize?: boolean) => {
    const colorSelected =
      selectedProd?.Tags?.find((tag) => tag?.Label === "Colors")?.Tags[0]?.Id || (selectedProd?.Colors && selectedProd?.Colors?.length > 0) ? selectedProd?.Colors?.[0].Id : undefined;

    if (isOneSize) {
      props.productToBasket({ Id: selectedProd?.Id ?? "", TagsIds: colorSelected ? [colorSelected] : [] });
      return;
    }
    if (!selectedProd?.Id || !tagSelected) {
      return;
    }
    if (!colorSelected || !tagSelected.Id) {
      return;
    }
    setSelectedTagId(tagSelected.Id);
    props.productToBasket({ Id: selectedProd.Id, TagsIds: [colorSelected, tagSelected.Id] });
  };

  useEffect(() => {
    if (isOneSize) {
      retrieveSelection(props?.selected, undefined, isOneSize);
    }
  }, [isOneSize, props.selected]);

  useEffect(() => {
    if (!isLoading) {
      resetSelection();
    }
  }, [isLoading]);

  const resetSelection = () => {
    setSelectedTagId(null);
  };

  return (
    <Flex width={[1]} flexDirection={"column"} {...props}>
      <Txt width={[1]} fontSize={[1]} color={colors.thirdBase} textAlign={"left"} variant='light'>
        {props.title}
      </Txt>
      <Flex flexDirection={"row"} $gap={0.4} justifyContent={["flex-start", "flex-start", "flex-start"]} flexWrap={"wrap"} marginTop={"10px"} width={["100%", "100%"]}>
        {/* {props.selected?.attributes?.SizeKey?
        props.productVariants?.filter((variant)=>variant?.attributes?.ColorKey === props?.selected?.attributes?.ColorKey).map((el,i) => (
         el.attributes?.SizeKey &&
         <Button
         
            padding={props.variant === 'xl' ? '2px 15px':'2px 30px'}
            key={el.Id! + i}
            variant="switcher"
            selected={el.Id === props?.selected?.Id}
            onClick={() => props.handleSelected(el)}
            lineHeight={"inherit"}
          >
          {t(`DV_Sizes_${el?.attributes?.SizeKey}`)}
          </Button>
        ))
      :<Txt>{t(i18Enum.Common_OneSize)}</Txt>} */}

        {!isOneSize ? (
          props.selected?.Sizes?.map(
            (el, i) =>
              el.Label && (
                <Button
                  padding={props.variant === "xl" ? "2px 15px" : "2px 30px"}
                  key={el.Id! + i}
                  variant='switcher'
                  selected={el.Id === selectedTagId}
                  onClick={() => {
                    retrieveSelection(props?.selected, el);
                  }}
                  lineHeight={"inherit"}
                >
                  {el.Label}
                </Button>
              )
          )
        ) : (
          <Button
            padding={props.variant === "xl" ? "2px 15px" : "2px 30px"}
            variant='switcher'
            selected={isOneSize}
            onClick={() => {
              // retrieveSelection(props?.selected, undefined, true);
            }}
            lineHeight={"inherit"}
          >
            {props.selected?.Sizes?.length === 1 ? props.selected?.Sizes[0].Label : t(i18Enum.Common_OneSize)}
          </Button>
        )}
      </Flex>
    </Flex>
  );
}

export default SelectSize;
